/* 
 * Scripts relacionados à ajax.
 */
var win_login;
var requestRunning = 0;
var requestAjax = false;
var ajax_tipo = 'auto';

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

function calcularData(offset) {
    var offset = typeof offset == 'undefined' ? -3 : offset;
    var data = new Date();
    var milisegundos_com_utc = data.getTime() + (data.getTimezoneOffset() * 60000);
    return new Date(milisegundos_com_utc + (3600000 * offset));
}

/**
 * ExcellentExport.
 */
var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
var fromCharCode = String.fromCharCode;
var INVALID_CHARACTER_ERR = ( function() {
        // fabricate a suitable error object
        try {
            document.createElement('$');
        } catch (error) {
            return error;
        }
    }());

// encoder
window.btoa || (window.btoa = function(string) {
    var a, b, b1, b2, b3, b4, c, i = 0, len = string.length, max = Math.max, result = '';

    while (i < len) {
        a = string.charCodeAt(i++) || 0;
        b = string.charCodeAt(i++) || 0;
        c = string.charCodeAt(i++) || 0;

        if (max(a, b, c) > 0xFF) {
            throw INVALID_CHARACTER_ERR;
        }

        b1 = (a >> 2) & 0x3F;
        b2 = ((a & 0x3) << 4) | ((b >> 4) & 0xF);
        b3 = ((b & 0xF) << 2) | ((c >> 6) & 0x3);
        b4 = c & 0x3F;

        if (!b) {
            b3 = b4 = 64;
        } else if (!c) {
            b4 = 64;
        }
        result += characters.charAt(b1) + characters.charAt(b2) + characters.charAt(b3) + characters.charAt(b4);
    }
    return result;
});

// decoder
window.atob || (window.atob = function(string) {
    string = string.replace(/=+$/, '');
    var a, b, b1, b2, b3, b4, c, i = 0, len = string.length, chars = [];

    if (len % 4 === 1)
        throw INVALID_CHARACTER_ERR;

    while (i < len) {
        b1 = characters.indexOf(string.charAt(i++));
        b2 = characters.indexOf(string.charAt(i++));
        b3 = characters.indexOf(string.charAt(i++));
        b4 = characters.indexOf(string.charAt(i++));

        a = ((b1 & 0x3F) << 2) | ((b2 >> 4) & 0x3);
        b = ((b2 & 0xF) << 4) | ((b3 >> 2) & 0xF);
        c = ((b3 & 0x3) << 6) | (b4 & 0x3F);

        chars.push(fromCharCode(a));
        b && chars.push(fromCharCode(b));
        c && chars.push(fromCharCode(c));
    }
    return chars.join('');
});


ExcellentExport = (function() {
    var version = "1.3";
    var uri = {excel: 'data:application/vnd.ms-excel;base64,', csv: 'data:application/csv;base64,'};
    var template = {excel: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'};
    var base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    };
    var format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
        });
    };

    var get = function(element) {
        if (!element.nodeType) {
            return document.getElementById(element);
        }
        return element;
    };

    var fixCSVField = function(value) {
        var fixedValue = value;
        var addQuotes = (value.indexOf(',') !== -1) || (value.indexOf('\r') !== -1) || (value.indexOf('\n') !== -1);
        var replaceDoubleQuotes = (value.indexOf('"') !== -1);

        if (replaceDoubleQuotes) {
            fixedValue = fixedValue.replace(/"/g, '""');
        }
        if (addQuotes || replaceDoubleQuotes) {
            fixedValue = '"' + fixedValue + '"';
        }
        return fixedValue;
    };

    var isNumber = function(n) {
        n = (n + '').replace(".","");
        n = (n + '').replace(",",".");

        return n - parseFloat( n ) >= 0;
    }

    var tableToCSV = function(table) {
        var data = "";
        for (var i = 0, row; row = table.rows[i]; i++) {
            for (var j = 0, col; col = row.cells[j]; j++) {
                var valor = fixCSVField(col.innerHTML);

                valor = (valor + '').replace(/("|\n)/g,' ');
                valor = (valor+'').trim();

                /*
                if(isNumber(valor) == true){
                    n = (n + '').replace(".","");
                    n = (n + '').replace(",","."); 
                }else{
                    valor = (valor + '').replace(/("|,|\n)/g,' ');
                }
                */

                valor = '"'+valor+'"'

                data = data + (j ? ',' : '') + valor;
            }
            data = data + "\r\n";
        }
        return data;
    };

    var ee = {
        /** @expose */
        excel: function(anchor, table, name) {
            table = get(table);
            var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML};
            var hrefvalue = uri.excel + base64(format(template.excel, ctx));
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        excelBase64_2: function(anchor, html, name) {
            var ctx = {worksheet: name || 'Worksheet', table: html};
            var hrefvalue = base64(format(template.excel, ctx));
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        excelBase64: function(anchor, table, name) {
            table = get(table);

            var cln = table.cloneNode(true);

            $(cln).find('span').remove();
            $(cln).find('div').remove();
            
            var html = (cln.innerHTML + "");
                html = String(html).replaceAll("<!--(.|\\s)*?-->", "");
                html = String(html).replaceAll("<button(.|\\s)*?button>", "");

            var ctx = {worksheet: name || 'Worksheet', table: html};
            var hrefvalue = base64(format(template.excel, ctx));
            anchor.href = hrefvalue;
            // Return true to allow the link to work

            cln = {};

            return hrefvalue;
        },

        excel2: function(anchor, table, name) {
            table = get(table);
            var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML};
            var hrefvalue = uri.excel + base64(format(template.excel, ctx));
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        excel3: function(anchor, html, name) {
            
            var ctx = {worksheet: name || 'Worksheet', table: html};
            var hrefvalue = uri.excel + base64(format(template.excel, ctx));
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        excel4: function(anchor, table, name) {
            table = get(table);
            var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML};
            var hrefvalue = format(template.excel, ctx);
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        excel5: function(anchor, table, name) {
            var ctx = {worksheet: name || 'Worksheet', table: table};
            //var hrefvalue = format(template.excel, ctx);
            var hrefvalue = uri.excel + format(template.excel, ctx);
            anchor.href = hrefvalue;
            // Return true to allow the link to work
            return hrefvalue;
        },

        /** @expose */
        csv: function(anchor, table) {
            table = get(table);
            var csvData = tableToCSV(table);
            var hrefvalue = uri.csv + base64(csvData);
            anchor.href = hrefvalue;
            return csvData;
        },

        /** @expose */
        csvBase64: function(anchor, table) {
            table = get(table);
    
            var cln = table.cloneNode(true);
    
            $(cln).find('span').remove();
            $(cln).find('div').remove();

            var csvData = tableToCSV(cln);
            csvData = String(csvData).replaceAll("<!--(.|\\s)*?-->", "");
            csvData = String(csvData).replaceAll("<button(.|\\s)*?button>", "");

            var hrefvalue = uri.csv + base64(csvData);
            anchor.href = hrefvalue;

            cln = {};

            return csvData;
        }
    };

    return ee;
}());

function printPDF(nome, elemento, titulo, filtro, user, versao, pagina, css){

    //var conteudo = document.getElementById('sua_div').innerHTML,
    //var conteudo = $(elemento).html();
    //tela_impressao = window.open('about:blank');

    //tela_impressao.document.write(conteudo);
    //tela_impressao.window.print();
    //tela_impressao.window.close();

    //var conteudo = document.getElementById('sua_div').innerHTML,
    //var conteudo = $(elemento).html();
    //tela_impressao = window.open('about:blank');

    //tela_impressao.document.write(conteudo);
    //tela_impressao.window.print();
    //tela_impressao.window.close();

        var tipo_print = 0;    

        var top = '<tr><th colspan="100">'+
                    '<section id="top" style="border: 1px solid;">'+
                        '<div class="center">'+
                            '<label style="font-size: 12px; font-weight: 600;">GCWEB - '+(grupo_empresarial+'').toUpperCase()+'</label>'+
                            '<label style="font-size: 12px; font-weight: 600;">'+titulo+'</label>'+
                            '<label style="font-size: 12px; font-weight: 600;">Filtro: '+filtro+'</label>'+
                        '</div>'+
                        '<div class="right">'+
                            '<label style="font-size: 12px; font-weight: 600;">'+moment().format('L')+' '+moment().format('LT')+'</label>'+
                            '<label style="font-size: 12px;" class="pagina">'+user+'</label>'+
                            '<label style="font-size: 12px;" class="pagina">Vr:'+versao+'</label>'+
                        '</div>'+
                    '</section> '+      
                '</th></tr>';

        var fim1 = '<tfoot><tr><th colspan="100"><div style="background-color:black;width:100%;height:2px;"></div></th></tr></tfoot>';
        var fim2 = ''; //<div style="border: 2px solid; border-style: dotted; width:100%;height:2px;"></div><br><div style="text-align:center">'+user+' / '+moment().format('L')+' '+moment().format('LT')+'</div>';

        var conteudo = $('#'+elemento).html();
        var win = window.open();  
        win.document.write('<page size="A4" style="width: 710px;"">' + conteudo + fim2 + '</page>'); 

        $(win.document.body).css('margin', 'unset'); 

        if(pagina == 1){
            var head = $(win.document.head).html() + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page_l.css">';
        }else{
            var head = $(win.document.head).html() + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page.css">';
        }

        head = head + $(win.document.head).html() + ' <script src="'+urlhost+'/build/assets/images/print.js"></script>';

        if(css != ''){
            head =  '<link rel="stylesheet" href="'+css+'">'    
        }

        $(win.document.head).html(head);

        var obj = $(win.document).find('table')[0];
        var tmp = $(obj).html();
        
        $(obj).html(fim1 + tmp);

        obj = $(win.document).find('thead')[0];
        tmp = $(obj).html();

        $(obj).html(top + tmp);

        //setTimeout(function(){ 
        //  win.print();  
        //    win.close();
        //}, 500);

        var scaleBy = 2;
        var w = 1000;
        var h = 1000;
        var div = win.document.body;
        var canvas = document.createElement('canvas');
        canvas.width = w * scaleBy;
        canvas.height = h * scaleBy;
        canvas.style.width = w + 'px';
        canvas.style.height = h + 'px';
        var context = canvas.getContext('2d');
        context.scale(scaleBy, scaleBy);

        html2canvas(div,{
            canvas:canvas,
            onrendered:function(canvas){

               var fator =  canvas.width / 270;

               var img = canvas.toDataURL("image/png");
               var doc = new jsPDF();
               doc.addImage(img,'JPEG',10,10, canvas.width / fator, canvas.height / fator);
               doc.save(nome+'.pdf');
               win.close();
            }
       });
    
}

function printElementById(divToPrint){

	var divToPrint = document.getElementById(divToPrint);
	var newWin = window.open("");
	newWin.document.write(divToPrint.outerHTML);
	newWin.print();
	newWin.close();

}

function getWinPrint(elemento){

    if(document.getElementById(elemento+'_gerar_print')){
        if(document.getElementById(elemento+'_print')){
            document.getElementById(elemento+'_print').remove();
            var iframe =  '<iframe id="'+elemento+'_print" style="width:100%; height:50%;" hidden> '+
                            ' <p>Sem suporte ao iframe</p>' +
                            '</iframe>';
            $('#'+elemento+'_gerar_print').prepend(iframe);
            var win = document.getElementById(elemento+'_print').contentWindow;
        }else{
            var iframe =  '<iframe id="'+elemento+'_print" style="width:100%; height:50%;" hidden> '+
                            ' <p>Sem suporte ao iframe</p>' +
                            '</iframe>';
            $('#'+elemento+'_gerar_print').prepend(iframe);
            var win = document.getElementById(elemento+'_print').contentWindow;
        }

    }else{
        var div_relatorio = '<div id="'+elemento+'_gerar_print"></div>';
        $(div_relatorio).insertAfter($('#'+elemento));
        if(document.getElementById(elemento+'_print')){
            document.getElementById(elemento+'_print').remove();
            var iframe = '<iframe id="'+elemento+'_print" style="width:100%; height:50%;" hidden> '+
                            ' <p>Sem suporte ao iframe</p>' +
                            '</iframe>';
            $('#'+elemento+'_gerar_print').prepend(iframe);
            var win = document.getElementById(elemento+'_print').contentWindow;
        }else{
            var iframe = '<iframe id="'+elemento+'_print" style="width:100%; height:50%;" hidden> '+
                            ' <p>Sem suporte ao iframe</p>' +
                            '</iframe>';
            $('#'+elemento+'_gerar_print').prepend(iframe);
            var win = document.getElementById(elemento+'_print').contentWindow;
        }

    }

    return win;
}

function printHtml2(elemento, titulo, filtro,versao, pagina, css, zoom){  

    var user = $('#usuario-descricao').val();

    var top =  '<div class="divFooter" id="top" style="border: 1px solid;">'+
            ' <div class="logo">'+
                '<img src="./assets/images/logo/'+path_dir_empresa+'logo.png">' +
            ' </div>'+
            '<div class="center" style="margin-left: 20px;">'+
                '<label style="font-size: 12px; font-weight: 600;">GCWEB - '+(grupo_empresarial+'').toUpperCase()+'</label>'+
                '<label style="font-size: 12px; font-weight: 600;">'+titulo+'</label>'+
                '<label style="font-size: 12px; font-weight: 600;">Filtro: '+filtro+'</label>'+
            '</div>'+
            '<div class="right">'+
                '<label style="font-size: 12px; font-weight: 600;">'+moment().format('L')+' '+moment().format('LT')+'</label>'+
                '<label style="font-size: 12px;" class="pagina">'+user+'</label>'+
                '<label style="font-size: 12px;" class="pagina">Vr:'+versao+'</label>'+
            '</div>'+
        '</div> '+      
    '';

    var fim1 = '<tfoot><tr><th colspan="100"><div style="background-color:black;width:100%;height:2px;"></div></th></tr></tfoot>';

    var conteudo = $('#'+elemento).html();
    var win = getWinPrint(elemento);
    
    if(typeof zoom == "undefined"){zoom = 1;}else{zoom = zoom/100;}
    var sty = ' style="zoom:'+zoom+'; width: 100%;";'; 

    win.document.write('<page size="A4">' + top  + '<div '+sty+'>' + conteudo + '</div>' + fim1 + '</page>');

    var head = $(win.document.head).html() + '';
    var css_buscar = $(document).find('link');
    
    for(var b = 0; b < css_buscar.length;b++){
        var tal = $(css_buscar[b]).attr('href');
        head = head + '<link rel="stylesheet" href="'+tal+'">';
    }

    if(pagina == 1){
        head = head + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page_paisagem.css">';
    }else{
        head = head + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page_retrato.css">';
    }

    if(css != ''){
        head += '<link rel="stylesheet" href="'+css+'">';
    }                

    head = head + ' <script src="'+urlhost+'/build/assets/images/print.js"></script>';

    $(win.document).find('head').prepend(head);
    $(win.document).find('.selected').removeClass('selected');
    $(win.document).find('.no-print').css('display', 'none');

    var timer = 700;
    
    win.setTimeout(function(){
        win.print();  
        win.close();
    }, timer);

}

function printHtml(elemento, titulo, filtro, user, versao, pagina, css, zoom){

    //var conteudo = document.getElementById('sua_div').innerHTML,
    //var conteudo = $(elemento).html();
    //tela_impressao = window.open('about:blank');

    //tela_impressao.document.write(conteudo);
    //tela_impressao.window.print();
    //tela_impressao.window.close();

        var tipo_print = 0;    

        var top = '<tr><th colspan="100">'+
                    '<section id="top" style="border: 1px solid;">'+
                        '<div class="center">'+
                            '<label style="font-size: 12px; font-weight: 600;">GCWEB - '+(grupo_empresarial+'').toUpperCase()+'</label>'+
                            '<label style="font-size: 12px; font-weight: 600;">'+titulo+'</label>'+
                            '<label style="font-size: 12px; font-weight: 600;">Filtro: '+filtro+'</label>'+
                        '</div>'+
                        '<div class="right">'+
                            '<label style="font-size: 12px; font-weight: 600;">'+moment().format('L')+' '+moment().format('LT')+'</label>'+
                            '<label style="font-size: 12px;" class="pagina">'+user+'</label>'+
                            '<label style="font-size: 12px;" class="pagina">Vr:'+versao+'</label>'+
                        '</div>'+
                    '</section> '+      
                '</th></tr>';

        var fim1 = '<tfoot><tr><th colspan="100"><div style="background-color:black;width:100%;height:2px;"></div></th></tr></tfoot>';
        var fim2 = ''; //<div style="border: 2px solid; border-style: dotted; width:100%;height:2px;"></div><br><div style="text-align:center">'+user+' / '+moment().format('L')+' '+moment().format('LT')+'</div>';

        if(tipo_print == 1){

            var $print = $('#'+elemento)
            .clone()
            .prepend(top)
            .addClass('print')
            .addClass('printable')
            .prependTo('body');

            $('.navbar').css('display', 'none'); 
            $('.container-fluid').css('display', 'none');

            // Stop JS execution
            window.print();

            $('.navbar').css('display', 'block'); 
            $('.container-fluid').css('display', 'block'); 

            // Remove div once printed
            $print.remove();
        }else{

            var conteudo = $('#'+elemento).html();
            var win = window.open();  
            

            if(typeof zoom == "undefined"){zoom = 1;}else{zoom = zoom/100;}
            var sty = ' style="zoom:'+zoom+'; width: 100%;";'; 

            win.document.write('<page size="A4">' + top + '<div '+sty+'>' + conteudo + '</div>' + fim1 + '</page>');

            if(pagina == 1){
                var head = $(win.document.head).html() + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page_l.css">';
            }else{
                var head = $(win.document.head).html() + '<link rel="stylesheet" href="'+urlhost+'/build/assets/images/page.css">';
            }

            if(css != ''){
                head += '<link rel="stylesheet" href="'+css+'">';
            }

            head = head + $(win.document.head).html() + ' <script src="'+urlhost+'/build/assets/images/print.js"></script>';

            $(win.document.head).html(head);

            setTimeout(function(){ 
                win.print();  
                win.close();
            }, 500);

        }
}

function createDownloadLink(anchorSelector, str, fileName){
    if(window.navigator.msSaveOrOpenBlob) {
        var fileData = [str];
        blobObject = new Blob(fileData);
        $(anchorSelector).click(function(){
            window.navigator.msSaveOrOpenBlob(blobObject, fileName);
        });
    } else {
        var url = "data:text/plain;charset=utf-8," + encodeURIComponent(str);
        $(anchorSelector).attr("download", fileName);               
        $(anchorSelector).attr("href", url);
    }
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);
    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

function exportTableToXlsGG(filename, table) {
    
    var str = ExcellentExport.excelBase64(filename,table);

    var blob = new Blob([base64toBlob(str, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});

    var link = document.createElement("a");
    var csvUrl = URL.createObjectURL(blob);
    link.href = csvUrl;
    link.style = "visibility:hidden";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

}

function exportTableToCsvGG(filename, table) {
    
    var str = ExcellentExport.csvBase64(filename,table);

    var csvData = str;
    var blob = new Blob([ csvData ], {
        type : "application/csv;charset=utf-8;"
    });

    var link = document.createElement("a");
    var csvUrl = URL.createObjectURL(blob);
    link.href = csvUrl;
    link.style = "visibility:hidden";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function exportTableToCsv(filename, table) {
    
    var str = ExcellentExport.csv(filename,table);

    //var link = window.document.createElement("a");
    //link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(str));
    //link.setAttribute("download", filename);
    //link.click();

    /*
    var link = window.document.createElement("a");
    link.setAttribute("href", "data:attachment/file;charset=utf-8,%EF%BB%BF" + encodeURI(str));
    link.setAttribute("download", filename);
    link.click();
    */

   str = str.replace('data:application/csv;base64,', '');
   str = str.replaceAll("<!--(.|\\s)*?-->", "");
   str = '\ufeff'+str;
   var blob = new Blob([str], {type : 'data:text/csv;charset=utf-8,%EF%BB%BF'});

   var link = document.createElement("a");
   var csvUrl = URL.createObjectURL(blob);
   link.href = csvUrl;
   link.style = "visibility:hidden";
   link.download = filename;
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
}

function exportToFile(filename, str) {

    //var link = window.document.createElement("a");
    //link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(str));
    //link.setAttribute("download", filename);
    //link.click();

    var link = window.document.createElement("a");
    link.setAttribute("href", "data:attachment/file;charset=utf-8,%EF%BB%BF" + encodeURI(str));
    link.setAttribute("download", filename);
    link.click();
}

function exportTableToXls(filename, table) {
    
    var str = ExcellentExport.excel(filename,table);

    //var link = window.document.createElement("a");
    //link.setAttribute("href", "data:application/vnd.ms-excel;base64" + encodeURI(str));
    //link.setAttribute("download", filename);
    //link.click();

    /*
    var link = window.document.createElement("a");
    link.setAttribute("href", "data:attachment/file;base64" + encodeURI(str));
    link.setAttribute("download", filename);
    link.click();
    */

    str = str.replace('data:application/vnd.ms-excel;base64,', '');

    var blob = new Blob([base64toBlob(str, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});

    var link = document.createElement("a");
    var csvUrl = URL.createObjectURL(blob);
    link.href = csvUrl;
    link.style = "visibility:hidden";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
}

function exportToXls(filename, rows, coll) {
    /*
        coll = [
            {COLUNA: 'ID', DESCRICAO:'ID', TIPO: 'INTEIRO'},
            {COLUNA: 'DESCRICAO', DESCRICAO:'DESCRIÇÃO', TIPO: 'STRING'},
            {COLUNA: 'DATA', DESCRICAO:'DATA', TIPO: 'DATA'},
        ];

        TIPOS:INTEIRO, STRING, DATA, NUMERICO

    */

    exportTo(filename, rows, coll, 1);
}

function exportToCsv(filename, rows, coll) {
    /*
        coll = [
            {COLUNA: 'ID', DESCRICAO:'ID', TIPO: 'INTEIRO'},
            {COLUNA: 'DESCRICAO', DESCRICAO:'DESCRIÇÃO', TIPO: 'STRING'},
            {COLUNA: 'DATA', DESCRICAO:'DATA', TIPO: 'DATA'},
        ];

        TIPOS:INTEIRO, STRING, DATA, NUMERICO

    */

    exportTo(filename, rows, coll, 2);
}

function exportToHtml(filename, rows, coll, pre_html, pos_html) {
    /*
        coll = [
            {COLUNA: 'ID', DESCRICAO:'ID', TIPO: 'INTEIRO'},
            {COLUNA: 'DESCRICAO', DESCRICAO:'DESCRIÇÃO', TIPO: 'STRING'},
            {COLUNA: 'DATA', DESCRICAO:'DATA', TIPO: 'DATA'},
        ];

        TIPOS:INTEIRO, STRING, DATA, NUMERICO

    */

    exportTo(filename, rows, coll, 4, pre_html, pos_html);
}

function exportTo(filename, rows, coll, tipo, pre_html, pos_html) {

    /*
        coll = [
            {COLUNA: 'ID', DESCRICAO:'ID', TIPO: 'INTEIRO'},
            {COLUNA: 'DESCRICAO', DESCRICAO:'DESCRIÇÃO', TIPO: 'STRING'},
            {COLUNA: 'DATA', DESCRICAO:'DATA', TIPO: 'DATA'},
        ];

        TIPOS:INTEIRO, STRING, DATA, NUMERICO, COR

    */

    var tag_abrir  = '';
    var tag_fechar = '';
    var lin_abrir  = '';
    var lin_fechar = '';
    var col_abrir  = '';
    var col_fechar = '';

    if(tipo == 1 || tipo == 3 || tipo == 4){
        tag_abrir  = '<tr #CLASS#>';
        tag_fechar = '</tr>';
        lin_abrir  = '<td #CLASS# style="background: #337ab7;color: white;">';
        lin_fechar = '</td>';
        col_abrir  = '<td #CLASS#>';
        col_fechar = '</td>';
    }else{
        tag_abrir  = '';
        tag_fechar = '';
        lin_abrir  = '"';
        lin_fechar = '",';
        col_abrir  = '"';
        col_fechar = '",';
    }

    var formatar = 0;

    if(coll){
        if(coll.length > 0){
            formatar = 1;
        }   
    }

    var csvFile = '';

    if(Object.keys(rows).length > 0){
        var l = tag_abrir;

        if(formatar == 1){
            angular.forEach(coll, function(coluna, key) {

                angular.forEach(rows[0], function(valor, campo) {
                    if(coluna.COLUNA == campo){
                        campo = coluna.DESCRICAO;

                        var str_tmp = lin_abrir + campo + lin_fechar;
                        
                        if(tipo == 4){
                            str_tmp = (str_tmp+'').replaceAll("#CLASS#", 'class="col_' + (coluna.TIPO+'').toLowerCase() + ' ' + 'col_' + key + '"'); 
                        }else{
                            str_tmp = (str_tmp+'').replaceAll("#CLASS#", ""); 
                        }

                        l += str_tmp;
                    }
                });
                
            });
        }else{
            angular.forEach(rows[0], function(valor, campo) {
                l += lin_abrir + campo + lin_fechar;
            });   
        }

        l += tag_fechar;

        csvFile += l;

        if(tipo == 2){
            csvFile = (csvFile + '').substring(0 , (csvFile + '').length -1);
        }
        csvFile += '\n';
    }


    angular.forEach(rows, function(linha, key1) {

        var l = tag_abrir;
        
        if(formatar == 1){
            angular.forEach(coll, function(coluna, key2) {
            //angular.forEach(linha, function(valor, campo) {

                var cor = '';

                //angular.forEach(coll, function(coluna, key) {
                angular.forEach(linha, function(valor, campo) {
                    if(coluna.COLUNA == campo){

                        if(coluna.TIPO == 'INTEIRO'){
                            //valor = valor.replace(".", "");
                            //valor = valor.replace(".", "");
                            //valor = valor.replace(",", ".");

                            valor = parseInt(valor);
                        }

                        if(coluna.TIPO == 'NUMERICO'){
                            //valor = valor.replace(".", "");
                            //valor = valor.replace(".", "");
                            //valor = valor.replace(",", ".");

                            valor = Number(valor);
                            valor = (valor+'').replace(".", ",");
                        }

                        if(coluna.TIPO == 'DATA'){
                            valor = valor;
                        }

                        if(coluna.TIPO == 'COR'){
                            cor = 'style="color: '+valor+';"';
                        }

                        if(coluna.TIPO == 'STRING'){
                            valor = (''+ valor + '');
                        }

                        if(tipo == 2){
                            valor = (valor + '').replace(/("|,|\n)/g,' ');
                        }

                        var str_tmp = col_abrir + valor + col_fechar;
                        
                        if(tipo == 4){
                            str_tmp = (str_tmp+'').replaceAll("#CLASS#", 'class="col_' + (coluna.TIPO+'').toLowerCase() + ' ' + 'col_' + key2 + '"'); 
                        }else{
                            str_tmp = (str_tmp+'').replaceAll("#CLASS#", ""); 
                        }

                        l += str_tmp; 
                    }
                });

            });
        }else{
            angular.forEach(linha, function(valor, campo) {

                valor = (valor + '').replace(/("|,|\n)/g,' ');

                l += col_abrir + valor + col_fechar; 

            });
        }

        l += tag_fechar;

        csvFile += l;

        if(tipo == 2){
            csvFile = (csvFile + '').substring(0 , (csvFile + '').length -1);
        }

        csvFile += '\n';
    });

    html = csvFile;

    if(tipo == 1){
        var str = ExcellentExport.excel3(filename,html);
        var link = window.document.createElement("a");
        link.setAttribute("href", "data:application/vnd.ms-excel;base64" + encodeURI(str));
        link.setAttribute("download", filename);
        link.click();
    }else{

        if(tipo == 2){

            var link = window.document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvFile));
            link.setAttribute("download", filename);
            link.click();

        }else{

            if(tipo == 4){

                var str = ExcellentExport.excelBase64_2(filename, pre_html + html + pos_html);
                str = str.replace('data:application/vnd.ms-excel;base64', '');
    
                var blob = new Blob([base64toBlob(str, 'text/html')], {});
    
                var link = document.createElement("a");
                var csvUrl = URL.createObjectURL(blob);
                link.href = csvUrl;
                link.style = "visibility:hidden";
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
            }else{
    
                var str = ExcellentExport.excelBase64_2(filename,html);
                str = str.replace('data:application/vnd.ms-excel;base64', '');
    
                var blob = new Blob([base64toBlob(str, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
    
                var link = document.createElement("a");
                var csvUrl = URL.createObjectURL(blob);
                link.href = csvUrl;
                link.style = "visibility:hidden";
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

function Errojs(mensagem) {
  this.mensagem = mensagem;
  this.nome = "Erro";
}

Errojs.prototype.toString = function() {
  return this.name + ': "' + this.message + '"';
};

/** 
 * Definir o crsf token para o ajax
 */
function ajaxSetup() {
	var token = $('meta[name="csrf_token"]').attr('content');
        
	$.ajaxSetup({
		headers: {
            'X-CSRF-TOKEN': token
        }
	});
    
    $.ajaxPrefilter(function(options, originalOptions, xhr) { // this will run before each request
    
        var websocket_redundancia = $('#websocket_redundancia').val();
        var socket_con = $('[name="_socket_token"]').val();

        // Verifica se a URL da requisição é para o mesmo servidor
        var isSameServer = options.url.startsWith(window.location.origin) || options.url.startsWith('/');

        if (websocket_redundancia == 1 && isSameServer) {
            xhr.setRequestHeader('x-websocket-redundancia', 1);
        }

        if ( socket_con != undefined && socket_con != "" ) {
            xhr.setRequestHeader('SOCKET-TOKEN', socket_con);
        }    
        
        var token = $('meta[name="csrf_token"]').attr('content');
        if (token) {
            return xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }
    });
}

/**
 * Setar valor do progressbar.
 * @param {event} e
 */
function progressPagina(e) {
    
    var percentual = 100;
    var socket = parseFloat($('._socket_token').val());
    
    if (socket > 0 && ajax_tipo == 'manual') {
        $('.progress-bar').css('transition','10s');
        percentual = 30;
    }
        
    
	if(e.lengthComputable) {
		$('.carregando-pagina .progress-bar')
			.attr({'aria-valuenow': e.loaded,'aria-valuemax': e.total})
			.css('width', (e.loaded*percentual) / e.total+'%');
    
	}
}

/**
 * Setar valor do progressbar.
 * @param {event} e
 */
function progressPagina2(e) {    
    
    var percentual = 100;
    var socket = parseFloat($('._socket_token').val());
    
    if (socket > 0 && ajax_tipo == 'manual') {
        $('.progress-bar').css('transition','10s');
        percentual = 30;
    }
    
	if(e.lengthComputable) {
		$('.carregando-pagina2 .progress-bar')
			.attr({'aria-valuenow': e.loaded,'aria-valuemax': e.total})
			.css('width', (e.loaded*percentual) / e.total+'%');
	}
}

function ajaxSendNotification(tipo, titulo, msg, usuario_id, ccusto){

    var dados = {
        'TIPO': tipo,
        'TITULO': titulo,
        'MSG': msg,
        'USUARIO_ID': usuario_id,
        'CCUSTO': ccusto,
    }

    execAjax1('POST', '/_11190/api/sendNewNotification', dados, null, function(e){

        showErro('Falha ao enviar notificação');
        console.log(e);

    });

}

function ajaxSetViewedNotification(item, index){

    var notificationId = $(`#NTF-${index}`); 

    execAjax1('POST', '/_11190/api/setViewedNotification', { 'ID': item.ID }, 

        function(r){

            var numNotifEl              = $('.notifications-amount');
            var numNotifElNavbar        = $('.notifications-amount-navbar');
            var numNotifMenuMobEl       = $('.go-notif-mobile');

            for(let index = 0; index < numNotifEl.length; index++) {
                const element = numNotifEl[index];

                var currentNotifAmount = $(element).text();
                currentNotifAmount = parseInt(currentNotifAmount);

                var newAmount = currentNotifAmount - 1;

                if(newAmount != 0){

                    $(element).text(newAmount);
                    numNotifElNavbar.text(newAmount);

                    numNotifMenuMobEl.attr('data-notifications', newAmount);

                } else {

                    $(element).text('');
                    numNotifElNavbar.text('');

                    numNotifMenuMobEl.attr('data-notifications', '');
                    $('.go-notif-mobile:before').css('visibility', 'hidden');

                }   
            }

            notificationId.remove();

        },

        function(e){

            showErro('Falha ao marcar notificação como visualizado');
            console.log(e);

        }

    );

}

function ajaxSetAllNotificationsViewed(){

    execAjax1('POST', '/_11190/api/setAllNotificationsViewed', {}, 

        function(r){

            var numNotifEl              = $('.notifications-amount');
            var numNotifElNavbar        = $('.notifications-amount-navbar');
            var numNotifMenuMobEl       = $('.go-notif-mobile');

            numNotifEl.text('');
            numNotifElNavbar.text('');

            numNotifMenuMobEl.attr('data-notifications', '');
            $('.go-notif-mobile:before').css('visibility', 'hidden');

            // Workaround para "esconder" as notificações marcadas como vistas
            $('#notifications-list').css('display', 'none');

        },

        function(e){

            showErro('Falha ao marcar todas as notificações como visualizado');
            console.log(e);

        }

    );

}
    
/**
 * Executa ação ajax, com progressbar.
 * 
 * @param {string} type Tipo de consulta. Ex.: POST, GET
 * @param {string} url_action Rota da consulta
 * @param {any} data Dados a ser enviado
 * @param {any} funcSuccess Função a ser executada no sucesso | Default = null
 * @param {any} funcErro Função a ser executada no erro | Default = null
 * @param {any} funcComplete Função a ser executada no complete | Default = null
 * @param {any} progress
 * @param {boolean} async Assincrono | Default = true
 * @param {boolean} cache Default = true
 * @param {any} contentType Default = 'application/x-www-form-urlencoded; charset=UTF-8'
 * @param {boolean} processData Default = true
 * @param {boolean} flag Default = true, se deve tentar novamente em caso de erro de ao conectar
 */
function execAjax1(type,url_action,data,funcSuccess,funcErro,funcComplete,progress,async,cache,contentType,processData,flag){
    
    requestRunning = 1;    

    if(typeof(progress) === "undefined"){
        progress = true;  
    }
    
    var upload = true;
    
    var socket = parseFloat($('._socket_token').val());
    
    if (socket > 0 && progress == 'manual') {
        $('.progress-bar').css('transition','0s');
        $('.progress-bar').css('background-color','rgb(0, 150, 136)');
    }
    
    if ( progress == 'manual' ) {
        progress = true;
        ajax_tipo = 'manual';
//        upload   = false;
    }

    var elementos = $(':focus');
    $(elementos).each(function( index ) {
        if($(this).is(':button') || $(this).is(':submit')){
            $(this).blur();
        }
    });
    
    flag		= (flag		    !== null && typeof(flag)		!== 'undefined') ? flag		    : true;
    async		= (async		!== null && typeof(async)		!== 'undefined') ? async		: true;
    cache		= (cache		!== null && typeof(cache)		!== 'undefined') ? cache		: true;
    contentType = (contentType	!== null && typeof(contentType)	!== 'undefined') ? contentType	: 'application/x-www-form-urlencoded; charset=UTF-8';

        return $.ajax({
            async		: async,
            type		: type,
            url			: url_action,
            data		: data,
            cache		: cache,
            contentType	: contentType,
            processData	: processData,
            xhr			: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload && upload) {
                    if(progress){
                        myXhr.upload.addEventListener('progress', progressPagina, false);
                    }else{
                        myXhr.upload.addEventListener('progress', progressPagina2, false);
                    }
                }

                return myXhr;
            },
            beforeSend	: function () {
                if(progress){
                    $('.carregando-pagina').fadeIn(200);
                }else{
                    $('.carregando-pagina2').fadeIn(200);
                }
            },
            success	: function (data) {
                
                //se for uma string e comessar com ' ' deve ser removido
                if(typeof data == 'string' && data.substring(0,1) == ' '){
                    data = data.substring(1);
                }
                
                funcSuccess ? funcSuccess(data) : null;                                         
                bootstrapInit();

                var  msg = data.SUCCESS_MSG || data.success_msg;
                if ( msg != undefined ) {
                    showSuccess(msg);
                }
            },
            error		: function (xhr) {
                if (xhr.status === 503 || ( typeof xhr.responseText !== 'undefined' && xhr.responseText == '' ) ) {
                    if(flag){
                        showAlert('Ops! Não obtivemos resposta do nosso servidor por motivo de manutenção ou sobrecarga. Mas não se preocupe, estamos reprocessando sua solicitação em 5 segundos... '+$('#MENSAGEM_ERRO').val());
                        setTimeout(function(){
                            execAjax1(type,url_action,data,funcSuccess,funcErro,funcComplete,progress,async,cache,contentType,processData, false);    
                        },5000);
                        return;
                    }
                }                  
                
                
                if (xhr.statusText !='abort') {
                    showErro(xhr);
                    funcErro ? funcErro(xhr) : null;
                }

                //sessão expirada
                if (xhr.status === 401) {

                    if ( win_login != null && !win_login.closed ) {

                        setTimeout(function() {
                            win_login.close();
                            win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                        }, 500);

                    } else {

                        setTimeout(function() {
                            win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                        }, 500);

                    }
                } 

            },
            complete	: function () {

                    //progress 1
                    $('.carregando-pagina').fadeOut(200);
                    $('.carregando-pagina2').fadeOut(200);

                    setTimeout(function() {
                        $('.carregando-pagina .progress .progress-bar')
                            .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                            .css('width', 0);

                        $('.carregando-pagina2 .progress .progress-bar')
                            .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                            .css('width', 0);
                    }, 300);

                funcComplete ? funcComplete() : null;

                //$(window).trigger('resize');  
                
				requestRunning = 0;
				requestAjax = false;
                ajax_tipo      = 'auto';
            }
		});
}

/**
 * Executa ação ajax, com progressbar.
 * 
 * @param {string} type Tipo de consulta. Ex.: POST, GET
 * @param {string} url_action Rota da consulta
 * @param {any} data Dados a ser enviado
 * @param {any} funcSuccess Função a ser executada no sucesso | Default = null
 * @param {any} funcErro Função a ser executada no erro | Default = null
 * @param {any} funcComplete Função a ser executada no complete | Default = null
 * @param {boolean} async Assincrono | Default = true
 * @param {boolean} cache Default = true
 * @param {any} contentType Default = 'application/x-www-form-urlencoded; charset=UTF-8'
 * @param {boolean} processData Default = true
 * @param {boolean} flag Default = true, se deve tentar novamente em caso de erro de ao conectar
 */
function execAjaxSemProgresso(type, url_action, data, funcSuccess, funcErro, funcComplete, async, cache, contentType, processData,flag){
    
    requestRunning = 1;    
    
    var upload = true;
    
    var socket = parseFloat($('._socket_token').val());

    var elementos = $(':focus');
    $(elementos).each(function( index ) {
        if($(this).is(':button') || $(this).is(':submit')){
            $(this).blur();
        }
    });
    
    flag		= (flag		    !== null && typeof(flag)		!== 'undefined') ? flag		    : true;
    async		= (async		!== null && typeof(async)		!== 'undefined') ? async		: true;
    cache		= (cache		!== null && typeof(cache)		!== 'undefined') ? cache		: true;
    contentType = (contentType	!== null && typeof(contentType)	!== 'undefined') ? contentType	: 'application/x-www-form-urlencoded; charset=UTF-8';
        return $.ajax({
            async		: async,
            type		: type,
            url			: url_action,
            data		: data,
            cache		: cache,
            contentType	: contentType,
            processData	: processData,
            xhr			: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload && upload) {

                }

                return myXhr;
            },
            beforeSend	: function () {

            },
            success	: function (data) {
                
                funcSuccess ? funcSuccess(data) : null;                                         
                bootstrapInit();

                var  msg = data.SUCCESS_MSG || data.success_msg;
                if ( msg != undefined ) {
                    showSuccess(msg);
                }
            },
            error		: function (xhr) {
                if (xhr.status === 503 || ( typeof xhr.responseText !== 'undefined' && xhr.responseText == '' ) ) {
                    if(flag){
                        showAlert('Ops! Não obtivemos resposta do nosso servidor por motivo de manutenção ou sobrecarga. Mas não se preocupe, estamos reprocessando sua solicitação em 5 segundos... '+$('#MENSAGEM_ERRO').val());
                        setTimeout(function(){
                            execAjaxSemProgresso(type,url_action,data,funcSuccess,funcErro,funcComplete,async,cache,contentType,processData, false);    
                        },5000);
                        return;
                    }
                }                  
                
                
                if (xhr.statusText !='abort') {
                    showErro(xhr);
                    funcErro ? funcErro(xhr) : null;
                }

                //sessão expirada
                if (xhr.status === 401) {

                    if ( win_login != null && !win_login.closed ) {

                        setTimeout(function() {
                            win_login.close();
                            win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                        }, 500);

                    } else {

                        setTimeout(function() {
                            win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                        }, 500);

                    }
                } 

            },
            complete	: function () {


                funcComplete ? funcComplete() : null;

                //$(window).trigger('resize');  
                
				requestRunning = 0;
				requestAjax = false;
                ajax_tipo      = 'auto';
            }
		});
}

/**
 * Executa ação ajax, com progressbar.
 * 
 * @param type Tipo de consulta. Ex.: POST, GET
 * @param url_action Rota da consulta
 * @param data Dados a ser enviado
 * @param funcSuccess Função a ser executada no sucesso | Default = null
 * @param funcErro Função a ser executada no erro | Default = null
 * @param funcComplete Função a ser executada no complete | Default = null
 * @param progress
 * @param async Assincrono | Default = true
 * @param cache Default = true
 * @param contentType Default = 'application/x-www-form-urlencoded; charset=UTF-8'
 * @param processData Default = true
 * @param returnData Default = false
 * @param {boolean} flag Default = true, se deve tentar novamente em caso de erro de ao conectar
 */
function execAjaxFile(type,url_action,data,funcSuccess,funcErro,funcComplete,progress,async,cache,contentType,processData,returnData = false,flag){
    
    requestRunning = 1;
    
    if(typeof(progress) === "undefined"){
        progress = true;  
    }
    
    var upload = true;
    
    var socket = parseFloat($('._socket_token').val());
    
    if (socket > 0 && progress == 'manual') {
        $('.progress-bar').css('transition','0s');
        $('.progress-bar').css('background-color','rgb(0, 150, 136)');
    }
    
    if ( progress == 'manual' ) {
        progress = true;
        ajax_tipo = 'manual';
    }

    flag		= (flag		    !== null && typeof(flag)		!== 'undefined') ? flag		    : true;
    async		= async			|| true;
    cache		= (cache		!== null && typeof(cache)		!== 'undefined') ? cache		: true;
    contentType = (contentType	!== null && typeof(contentType)	!== 'undefined') ? contentType	: 'application/x-www-form-urlencoded; charset=UTF-8';

    var elementos = $(':focus');
    $(elementos).each(function( index ) {
        if($(this).is(':button') || $(this).is(':submit')){
            $(this).blur();
        }
    });

    return $.ajax({
        type		: type,
        url			: url_action,
        data		: data,
        contentType	: false,
        processData	: false,
        xhr			: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload && upload) {
                if(progress){
                    myXhr.upload.addEventListener('progress', progressPagina, false);
                }else{
                    myXhr.upload.addEventListener('progress', progressPagina2, false);
                }
            }

            return myXhr;
        },
        beforeSend	: function () {
            if(progress){
                $('.carregando-pagina').fadeIn(200);
            }else{
                $('.carregando-pagina2').fadeIn(200);
            }
        },
        success		: function (data) {
            
            funcSuccess ? funcSuccess(data) : null;                                         
            bootstrapInit();

            var  msg = data.SUCCESS_MSG || data.success_msg;
            if ( msg != undefined ) {
                showSuccess(msg);
            }
        },
        error		: function (xhr) {
            if (xhr.status === 503 || ( typeof xhr.responseText !== 'undefined' && xhr.responseText == '' ) ) {
                if(flag){
                    showAlert('Ops! Não obtivemos resposta do nosso servidor por motivo de manutenção ou sobrecarga. Mas não se preocupe, estamos reprocessando sua solicitação em 5 segundos... '+$('#MENSAGEM_ERRO').val());
                    setTimeout(function(){
                        execAjax1(type,url_action,data,funcSuccess,funcErro,funcComplete,progress,async,cache,contentType,processData,false);    
                    },5000);
                    return;
                }
            }                  
            
            if (xhr.statusText !='abort') {
                showErro(xhr);
                funcErro ? funcErro(xhr) : null;
            }

            //sessão expirada
            if (xhr.status === 401) {

                if ( win_login != null && !win_login.closed ) {

                    setTimeout(function() {
                        win_login.close();
                        win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                    }, 500);

                } else {

                    setTimeout(function() {
                        win_login = winPopUp(document.location.origin, 'login-modal', {width:400,height:560});
                    }, 500);

                }
            } 

        },
        complete	: function () {

                //progress 1
                $('.carregando-pagina').fadeOut(200);
                $('.carregando-pagina2').fadeOut(200);

                setTimeout(function() {
                    $('.carregando-pagina .progress .progress-bar')
                        .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                        .css('width', 0);

                    $('.carregando-pagina2 .progress .progress-bar')
                        .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                        .css('width', 0);
                }, 300);

            funcComplete ? funcComplete() : null;

            //$(window).trigger('resize');  
            
            requestRunning = 0;
            ajax_tipo      = 'auto';
        }
    });
}

/**
 * Executa ação ajax, com ícone refresh.
 * 
 * @param type Tipo de consulta. Ex.: POST, GET
 * @param url_action Rota da consulta
 * @param data Dados a ser enviado
 * @param funcSuccess Função a ser executada no sucesso | Default = null
 * @param funcErro Função a ser executada no erro | Default = null
 * @param btn_filtro Botão que contém o ícone refresh
 * @param cache Default = true
 * @param contentType Default = 'application/x-www-form-urlencoded; charset=UTF-8'
 * @param processData Default = true
 */
function execAjax2(type,url_action,data,funcSuccess,funcErro,btn_filtro,cache,contentType,processData){
    
    requestRunning = 1;
    
	cache		= (cache		!== null && typeof(cache)		!== 'undefined') ? cache		: true;
	contentType = (contentType	!== null && typeof(contentType)	!== 'undefined') ? contentType	: 'application/x-www-form-urlencoded; charset=UTF-8';
	processData = (contentType	!== null && typeof(processData)	!== 'undefined') ? processData	: true;
        
    var elementos = $(':focus');
    $(elementos).each(function( index ) {
        if($(this).is(':button') || $(this).is(':submit')){
            $(this).blur();
        }
    });

        $.ajax({
            type		: type,
            url			: url_action,
            data		: data,
            cache		: cache,
            contentType	: contentType,
            processData	: processData,
            beforeSend	: function () {
                if (btn_filtro !== false){
                $(btn_filtro)
                    .children()
                    .addClass('fa-circle-o-notch');
                }
            },
            success		: function (data) {

                funcSuccess ? funcSuccess(data) : null;
                bootstrapInit();

            },
            error		: function (xhr) {

                showErro(xhr);
                funcErro ? funcErro(xhr) : null;

                //sessão expirada
                if (xhr.status === 401) {

                    if ( win_login != null && !win_login.closed ) {

                        setTimeout(function() {
                            win_login.close();
                            win_login = winPopUp('/', 'login-modal', {width:400,height:560});
                        }, 500);

                    } else {

                        setTimeout(function() {
                            win_login = winPopUp('/', 'login-modal', {width:400,height:560});
                        }, 500);

                    }
                }
            },
            complete	: function() {

                if (btn_filtro !== false) {

                    $(btn_filtro)
                        .children()
                        .removeClass('fa-circle-o-notch');
                }
                
                requestRunning = 0;

            }
        });
}


/**
 * Executa ação ajax, com progressbar via socket.
 * 
 * @param type Tipo de consulta. Ex.: POST, GET
 * @param url_action Rota da consulta
 * @param data Dados a ser enviado
 * @param funcSuccess Função a ser executada no sucesso | Default = null
 * @param funcErro Função a ser executada no erro | Default = null
 * @param funcComplete Função a ser executada no complete | Default = null
 * @param progress
 * @param async Assincrono | Default = true
 * @param cache Default = true
 * @param contentType Default = 'application/x-www-form-urlencoded; charset=UTF-8'
 * @param processData Default = true
 */
function execAjax3(type,url_action,data,funcSuccess,funcErro,funcComplete,progress,async,cache,contentType,processData){
    
    var _socket_token = $('._socket_token').val();

    requestRunning = 1;
    if(typeof(progress) === "undefined"){
        progress = true;  
    }

    data['_socket_token'] = _socket_token;

    async       = async         || true;
    cache       = (cache        !== null && typeof(cache)       !== 'undefined') ? cache        : true;
    contentType = (contentType  !== null && typeof(contentType) !== 'undefined') ? contentType  : 'application/x-www-form-urlencoded; charset=UTF-8';
    processData = (contentType  !== null && typeof(processData) !== 'undefined') ? processData  : true;

    var elementos = $(':focus');
    $(elementos).each(function( index ) {
        if($(this).is(':button') || $(this).is(':submit')){
            $(this).blur();
        }
    });

        return $.ajax({
            async       : async,
            type        : type,
            url         : url_action,
            data        : data,
            cache       : cache,
            contentType : contentType,
            processData : processData,
            xhr         : function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                    if(progress){
                        //myXhr.upload.addEventListener('progress', progressPagina, false);
                    }else{
                        //myXhr.upload.addEventListener('progress', progressPagina2, false);
                    }
                }

                return myXhr;
            },
            beforeSend  : function () {
                if(progress){
                    $('.carregando-pagina').fadeIn(200);
                }else{
                    $('.carregando-pagina2').fadeIn(200);
                }
            },
            success     : function (data) {

                funcSuccess ? funcSuccess(data) : null;                                         
                bootstrapInit();

            },
            error       : function (xhr) {

                showErro(xhr);
                funcErro ? funcErro(xhr) : null;

                //sessão expirada
                if (xhr.status === 401) {

                    if ( win_login != null && !win_login.closed ) {

                        setTimeout(function() {
                            win_login.close();
                            win_login = winPopUp('/', 'login-modal', {width:400,height:560});
                        }, 500);

                    } else {

                        setTimeout(function() {
                            win_login = winPopUp('/', 'login-modal', {width:400,height:560});
                        }, 500);

                    }
                }

            },
            complete    : function () {

                    //progress 1
                    $('.carregando-pagina').fadeOut(200);
                    $('.carregando-pagina2').fadeOut(200);

                    setTimeout(function() {
                        $('.carregando-pagina .progress .progress-bar')
                            .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                            .css('width', 0);

                        $('.carregando-pagina2 .progress .progress-bar')
                            .attr({'aria-valuenow': 0,'aria-valuemax': 0})
                            .css('width', 0);
                    }, 300);

                funcComplete ? funcComplete() : null;

                //$(window).trigger('resize');  
                
                requestRunning = 0;
            }
        });
}

function opemEmailFile(asunto, corpo, email, name, table, file_tipe, historico_mensagem, tabela, tabela_id, modulo_email){
    var str_file = '';

    if (typeof file_tipe == 'undefined') {
        file_tipe = '';
    }

    if (typeof historico_mensagem == 'undefined') {
        historico_mensagem  = '';
        tabela              = '';
        tabela_id           = '';
    }
    
    if (typeof modulo_email == 'undefined') {
        modulo_email = 0;
    }
    
    if(file_tipe == 'CSV' || file_tipe == 'XLS' || file_tipe == ''){
        str_file = ''
        +'<div class="itens-inputs">'
        +'  <div class="form-group">'
        +'      <label>Arquivo:</label>'
        +'      <select class="tipe-file-send">'
        +'          <option value="CSV">CSV</option>'
        +'          <option selected="selected" value="XLS">XLS</option>'
        +'      </select>'
        +'  </div>'
        +'</div>';
    }else{
        str_file = ''
        +'<div class="itens-inputs">'
        +'  <div class="form-group">'
        +'      <label>Arquivo:</label>'
        +'      <select class="tipe-file-send">'
        +'          <option selected="selected" value="'+file_tipe+'">'+file_tipe+'</option>'
        +'      </select>'
        +'  </div>'
        +'</div>';    
    }

    addConfirme('Email',''

        +'<input type="text" style="display: none;" name="titulo" class="name-file-send"  value="'+name+'"      autocomplete="off">'
        +'<input type="text" style="display: none;" name="titulo" class="table-file-send" value="'+table+'"     autocomplete="off">'

        + str_file

        +'<div class="itens-inputs">'   
        +'  <div class="form-group">'
        +'      <label>Para:</label>'
        +'      <input type="text" style="width: 100% !important;text-transform: lowercase;" name="titulo" class="email-file-send form-control input-maior" value="'+email+'" autocomplete="off">'
        +'  </div>'
        +'</div>'

        +'<div class="itens-inputs">'
        +'  <div class="form-group">'
        +'      <label>Assunto:</label>'
        +'      <input type="text" style="width: 100% !important;" name="titulo" class="assunto-file-send form-control input-maior normal-case" value="'+asunto+'" autocomplete="off">'
        +'  </div>'
        +'</div>'
        +'<div class="itens-inputs">'
        +'    <div class="form-group">'
        +'        <label>Descrição:</label>'
        +'        <textarea name="" class="form-control corpo-file-send normal-case" rows="5" cols="70" value="'+corpo+'">'+corpo+'</textarea>'
        +'    </div>'
        +'</div>',
            [
                {desc:'Enviar',class:'btn-primary btn-confirm-sim',ret:'1',hotkey:'enter',glyphicon:'glyphicon-ok'},
                {desc:'Cancelar',class:'btn-danger btn-confirm-can btn-voltar',ret:'2',hotkey:'esc',glyphicon:'glyphicon-ban-circle'}
            ],
            [
                {ret:1,func:function(){
                    var email  = $('.email-file-send').val();
                    var asunto = $('.assunto-file-send').val();
                    var corpo  = $('.corpo-file-send').val();
                    var name   = $('.name-file-send').val();
                    var table  = $('.table-file-send').val();
                    var tipe   = $('.tipe-file-send').val();

                    var file = name;

                    if(tipe == 'CSV'){
                        file = ExcellentExport.csv(name,table);
                    }

                    if(tipe == 'XLS'){
                        file = ExcellentExport.excel4(name,table);
                    }

                    sendEmailFile(email,file, tipe ,asunto, corpo, name, table, historico_mensagem, tabela, tabela_id, modulo_email).then(function(){
                        showSuccess('Arquivo enviado por email.');
                        $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                        $('.modal.confirm').remove();
                    });

                }},
                {ret:2,func:function(){
                    $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                    $('.modal.confirm').remove();
                }}
            ],'N'    
    );  
}

function opemEmailFile2(asunto, corpo, email, name, table, file_tipe, historico_mensagem, tabela, tabela_id, modulo_email){
    var str_file = '';

    if (typeof file_tipe == 'undefined') {
        file_tipe = '';
    }

    if (typeof historico_mensagem == 'undefined') {
        historico_mensagem  = '';
        tabela              = '';
        tabela_id           = '';
    }

    if (typeof modulo_email == 'undefined') {
        modulo_email = 0;
    }
    
    str_file = ''
    +'<div class="itens-inputs">'
    +'  <div class="form-group">'
    +'      <label>Arquivo:</label>'
    +'      <select class="tipe-file-send">'
    +'          <option selected="selected" value="'+file_tipe+'">'+file_tipe+'</option>'
    +'      </select>'
    +'  </div>'
    +'</div>';    

    var tmp_fl = '';
    for (var index = 0; index < name.length; index++) {
        tmp_fl += '<input type="text" style="display: none;" name="titulo" class="name-file-send"  value="'+name[index]+'" autocomplete="off">';
    }

    addConfirme('Email',''

        + tmp_fl
        + str_file

        +'<div class="itens-inputs">'   
        +'  <div class="form-group">'
        +'      <label>Para:</label>'
        +'      <input type="text" style="width: 100% !important;text-transform: lowercase;" name="titulo" class="email-file-send form-control input-maior" value="'+email+'" autocomplete="off">'
        +'  </div>'
        +'</div>'

        +'<div class="itens-inputs">'
        +'  <div class="form-group">'
        +'      <label>Assunto:</label>'
        +'      <input type="text" style="width: 100% !important;" name="titulo" class="assunto-file-send form-control input-maior normal-case" value="'+asunto+'" autocomplete="off">'
        +'  </div>'
        +'</div>'
        +'<div class="itens-inputs">'
        +'    <div class="form-group">'
        +'        <label>Descrição:</label>'
        +'        <textarea name="" class="form-control corpo-file-send normal-case" rows="5" cols="70" value="'+corpo+'">'+corpo+'</textarea>'
        +'    </div>'
        +'</div>',
            [
                {desc:'Enviar',class:'btn-primary btn-confirm-sim',ret:'1',hotkey:'enter',glyphicon:'glyphicon-ok'},
                {desc:'Cancelar',class:'btn-danger btn-confirm-can btn-voltar',ret:'2',hotkey:'esc',glyphicon:'glyphicon-ban-circle'}
            ],
            [
                {ret:1,func:function(){
                    var email  = $('.email-file-send').val();
                    var asunto = $('.assunto-file-send').val();
                    var corpo  = $('.corpo-file-send').val();
                    var table  = $('.table-file-send').val();
                    var tipe   = $('.tipe-file-send').val();
                    
                    var name   = $('.name-file-send');

                    var file =  [];
                    for (var index = 0; index < name.length; index++) {
                        file.push($(name[index]).val());
                    }

                    name = file;

                    sendEmailFile2(email,file, tipe ,asunto, corpo, name, table, historico_mensagem, tabela, tabela_id, modulo_email).then(function(){
                        showSuccess('Arquivo enviado por email.');
                        $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                        $('.modal.confirm').remove();
                    });

                }},
                {ret:2,func:function(){
                    $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                    $('.modal.confirm').remove();
                }}
            ],'N'    
    );  
}

function openEmailFiles(asunto, corpo, email, files, historico_mensagem, tabela, tabela_id){
    var str_file = '';

    if (typeof historico_mensagem == 'undefined') {
        historico_mensagem  = '';
        tabela              = '';
        tabela_id           = '';
    }
    
    var optionsTypes = '';

    var tmp_fl = '';
    for (var indexTypes = 0; indexTypes < files.length; indexTypes++) {
        var element         = files[indexTypes];
        var type            = trim_null(element.TYPE);
        var typeDesc        = trim_null(element.DESC);

        optionsTypes        += '<option value="'+type+'">'+typeDesc+'</option>';

        for (var indexFiles = 0; indexFiles < element.FILES.length; indexFiles++) {
            var file = element.FILES[indexFiles];
            tmp_fl += '<input type="text" id="'+type+'" style="display: none;" name="titulo" class="name-file-send"  value="'+file+'" autocomplete="off">';
        }
    }

    str_file = ''
    +'<div class="itens-inputs">'
    +'  <div class="form-group">'
    +'      <label>Arquivo:</label>'
    +'      <select class="tipe-file-send">'
    + optionsTypes
    +'      </select>'
    +'  </div>'
    +'</div>';    

    addConfirme('Email',''

        + tmp_fl
        + str_file

        +'<div class="itens-inputs">'   
        +'  <div class="form-group">'
        +'      <label>Para:</label>'
        +'      <input type="text" style="width: 100% !important;text-transform: lowercase;" name="titulo" class="email-file-send form-control input-maior" value="'+email+'" autocomplete="off">'
        +'  </div>'
        +'</div>'

        +'<div class="itens-inputs">'
        +'  <div class="form-group">'
        +'      <label>Assunto:</label>'
        +'      <input type="text" style="width: 100% !important;" name="titulo" class="assunto-file-send form-control input-maior normal-case" value="'+asunto+'" autocomplete="off">'
        +'  </div>'
        +'</div>'
        +'<div class="itens-inputs">'
        +'    <div class="form-group">'
        +'        <label>Descrição:</label>'
        +'        <textarea name="" class="form-control corpo-file-send normal-case" rows="5" cols="70" value="'+corpo+'">'+corpo+'</textarea>'
        +'    </div>'
        +'</div>',
            [
                {desc:'Enviar',class:'btn-primary btn-confirm-sim',ret:'1',hotkey:'enter',glyphicon:'glyphicon-ok'},
                {desc:'Cancelar',class:'btn-danger btn-confirm-can btn-voltar',ret:'2',hotkey:'esc',glyphicon:'glyphicon-ban-circle'}
            ],
            [
                {ret:1,func:function(){
                    var email  = $('.email-file-send').val();
                    var asunto = $('.assunto-file-send').val();
                    var corpo  = $('.corpo-file-send').val();
                    var table  = $('.table-file-send').val();
                    var tipe   = $('.tipe-file-send').val();
                    
                    var name   = $('.name-file-send');

                    var file =  [];
                    for (var index = 0; index < name.length; index++) {
                        if(tipe == 'XML_PDF'){
                            file.push($(name[index]).val());
                        }else if(tipe == name.attr('id')){
                            file.push($(name[index]).val());
                        }
                    }

                    name = file;

                    sendEmailFile2(email,file, tipe ,asunto, corpo, name, table, historico_mensagem, tabela, tabela_id).then(function(){
                        showSuccess('Arquivo enviado por email.');
                        $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                        $('.modal.confirm').remove();
                    });

                }},
                {ret:2,func:function(){
                    $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                    $('.modal.confirm').remove();
                }}
            ],'N'    
    );  
}

function sendEmailSuporte(email, dataimg, assunto, corpo){

	console.log(email, dataimg, assunto, corpo);
    return new Promise(function(resolve, reject) {

        var dados = {
            EMAIL     : email,
            ASSUNTO   : assunto,
            CORPO     : corpo,
            FILE      : dataimg
        };

        execAjax1('POST','/sendEmailSuporte',dados,
        function(resposta) {
            resolve(resposta);
        },
        function(xhr){
            reject(xhr);
        });      
    });
}

function sendEmailFile(email, file, file_tipe, assunto, corpo, name, table, historico_mensagem, tabela, tabela_id, modulo_email){
    return new Promise(function(resolve, reject) {

        if(typeof modulo_email == 'undefined'){
            modulo_email = 0;
        }

        var dados = {
            FILE_TIPE : file_tipe,
            EMAIL     : email,
            FILE      : file,
            ASSUNTO   : assunto,
            CORPO     : corpo,
            MOME      : name,
            TABLE     : table,
            MODULO_EMAIL : modulo_email
        };

        if(typeof historico_mensagem != 'undefined'){
            dados.HISTORICO_MENSAGEM 	= historico_mensagem;
            dados.TABELA 				= tabela;
            dados.TABELA_ID 			= tabela_id;
        }
        
        execAjax1('POST','/sendEmailFile',dados,
        function(resposta) {
            resolve(resposta);
        },
        function(xhr){
            reject(xhr);
        });      
    });
}


function sendEmailFile2(email, file, file_tipe, assunto, corpo, name, table, historico_mensagem, tabela, tabela_id, modulo_email){
    return new Promise(function(resolve, reject) {

        if(typeof modulo_email == 'undefined'){
            modulo_email = 0;
        }
        
        var dados = {
            FILE_TIPE : file_tipe,
            EMAIL     : email,
            FILE      : file,
            ASSUNTO   : assunto,
            CORPO     : corpo,
            MOME      : name,
            TABLE     : table,
            MODULO_EMAIL : modulo_email
        };

        if(typeof historico_mensagem != 'undefined'){
            dados.HISTORICO_MENSAGEM 	= historico_mensagem;
            dados.TABELA 				= tabela;
            dados.TABELA_ID 			= tabela_id;
        }
        
        execAjax1('POST','/sendEmailFile2',dados,
        function(resposta) {
            resolve(resposta);
        },
        function(xhr){
            reject(xhr);
        });      
    });
}

function requestPost(param)
{     
    return new Promise(function(resolve, reject) {
        execAjax1('POST',param.rota_ajax,param.dados,
        function(resposta) {
            resolve(resposta);
        },
        function(xhr){
            reject(xhr);
        });      
    });
}

var loading_element = false;

function loading(element_loading) {
    
    if ( typeof element_loading == 'string' && element_loading == 'hide' ) {
        $(loading_element).fadeIn(2000);
        $('.bubblingG').fadeOut(2000,function(){
            $(this).remove();
        });
    } else {
    
        var loading =  '<div class="bubblingG" style="display: none; position: fixed; left: calc(50% - 95px); top: calc(50% - 60px); z-index: 99;"><span id="bubblingG_1"></span><span id="bubblingG_2"></span><span id="bubblingG_3"></span></div>';
        loading_element = element_loading;
        $(loading_element).parent().append(loading);
        $(loading_element).fadeOut();
        $('.bubblingG').fadeIn(2000);
    }
}

(function($) {
	
	$(function() {
		
		ajaxSetup();
 
        if ('Notification' in window) {
            let NotificationRequestPermission = Notification.permission;
        
            if (NotificationRequestPermission === 'default') {
                Notification.requestPermission().then((permission) => {
                    NotificationRequestPermission = permission;
                    console.log('Permissão concedida:', permission);
                });
            }
        } else {
            console.log('Notificações não são suportadas neste navegador.');
        }
        
    });
    
})(jQuery);