/**
 * Script para atalhos do teclado.
 */
(function($) {
	/*Variável utilizada na condicional do hotkey f11*/ 
	var key_detect_f11 = 0;

	/**
	 * 	em uma tabela o tab vai para o proximo imput e enter dece
	 */
	function atalhoInputTabela(){

		$(document).on('keypress', '.atalho-input-tabela-enter', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				if (code === 13 && e.shiftKey == false) {

                    var tr = $(elmt).closest('tr');
                    var td = $(elmt).closest('td');
                    
                    var novofoco = $(td).next().find('.atalho-input-tabela-enter');

                    if(novofoco.length > 0){
                        $(novofoco).focus().select();
                        e.stopPropagation();
                        e.preventDefault();
                    }else{
                        var tr = $(tr).next();
                        novofoco = $(tr).find('.atalho-input-tabela-enter').first();
                        $(novofoco).focus().select();
                        e.stopPropagation();
                        e.preventDefault();
                    }

					e.preventDefault();
					return false;
				}
				
				if (code === 13 && e.shiftKey == true) {

                    var tr = $(elmt).closest('tr');
                    var td = $(elmt).closest('td');
                    
                    var novofoco = $(td).prev().find('.atalho-input-tabela-enter');

                    if(novofoco.length > 0){
                        $(novofoco).focus().select();
                        e.stopPropagation();
                        e.preventDefault();
                    }else{
                        var tr = $(tr).prev();
                        novofoco = $(tr).find('.atalho-input-tabela-enter').last();
                        $(novofoco).focus().select();
                        e.stopPropagation();
                        e.preventDefault();
                    }

					e.preventDefault();
					return false;
				}
			}
		});
		
		$(document).on('keypress', '.atalho-input-tabela', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				var tr = $(elmt).closest('tr');
				var td = $(elmt).closest('td');

				if (code === 13 && e.shiftKey == false) {
					
					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).next();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							list.each(function( index ) {
								if($cont == $index){
									$(this).find('.atalho-input-tabela').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}else{
							var dy = $(elmt).closest('tbody');
							var tr = $(dy).find('.itbl').first();

							$cont = 0;
							var list = $(tr).find('td');

							if(list.length > 0){
								list.each(function( index ) {
									if($cont == $index){
										$(this).find('.atalho-input-tabela').focus().select();
										e.stopPropagation();
										e.preventDefault();
									}
									$cont++;
								});
							}
						}
					}

					e.preventDefault();
					return false;
				}
				
				if (code === 13 && e.shiftKey == true) {

					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).prev();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							
							list.each(function( index ) {
								if($cont == $index){
									$(this).find('.atalho-input-tabela').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}
					}

					e.preventDefault();
					return false;
				}
			}
		});

		$(document).on('keypress', '.atalho-input-tabela-2', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				var tr = $(elmt).closest('tr');
				var td = $(elmt).closest('td');

				if (code === 13 && e.shiftKey == false) {
					
					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).next();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							list.each(function( index ) {
								if($cont == $index){
									$(this).parent().focus();
									$(this).find('.atalho-input-tabela-2').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}else{
							var dy = $(elmt).closest('tbody');
							var tr = $(dy).find('.itbl').first();

							$cont = 0;
							var list = $(tr).find('td');

							if(list.length > 0){
								list.each(function( index ) {
									if($cont == $index){
										$(this).parent().focus();
										$(this).find('.atalho-input-tabela-2').focus().select();
										e.stopPropagation();
										e.preventDefault();
									}
									$cont++;
								});
							}
						}
					}

					e.preventDefault();
					return false;
				}
				
				if (code === 13 && e.shiftKey == true) {

					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).prev();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							
							list.each(function( index ) {
								if($cont == $index){
									$(this).parent().focus();
									$(this).find('.atalho-input-tabela-2').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}
					}

					e.preventDefault();
					return false;
				}
			}
		});
		
		$(document).on('keypress', '.atalho-input-tabela-accordion', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var idElement = $(this).attr("id");

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				var tr = $(elmt).closest('tr');
				var td = $(elmt).closest('td');

				if (code === 13 && e.shiftKey == false) {
					
					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).next();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							list.each(function( index ) {
								if($cont == $index){
									// $(this).parent().focus();
									$(this).find('.atalho-input-tabela-accordion#'+idElement).focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}else{
							var dy = $(elmt).closest('tbody');
							var tr = $(dy).find('.itbl').first();

							$cont = 0;
							var list = $(tr).find('td');

							if(list.length > 0){
								list.each(function( index ) {
									if($cont == $index){

										// $(this).parent().focus();
										$(this).find('.atalho-input-tabela-accordion#'+idElement).focus().select();
										
										e.stopPropagation();
										e.preventDefault();
									}
									$cont++;
								});
							}
						}
					}

					e.preventDefault();
					return false;
				}
				
				if (code === 13 && e.shiftKey == true) {

					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).prev();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							
							list.each(function( index ) {
								if($cont == $index){
									// $(this).parent().focus();
									$(this).find('.atalho-input-tabela-accordion#'+idElement).focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}
					}

					e.preventDefault();
					return false;
				}
			}
		});

		/* Atalho input para transitar entre campos e pular linhas com o enter */
		$(document).on('keypress', '.atalho-input-tabela-transitation', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				var tr = $(elmt).closest('tr');
				var td = $(elmt).closest('td');

				if (code === 13 && e.shiftKey == false) {
					
					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).next();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							list.each(function( index ) {
								if($cont == $index){
									$(this).find('.atalho-input-tabela-transitation').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}else{
							var dy = $(elmt).closest('tbody');
							var tr = $(dy).find('.itbl').first();

							$cont = 0;
							var list = $(tr).find('td');

							if(list.length > 0){
								list.each(function( index ) {
									if($cont == $index){
										$(this).find('.atalho-input-tabela-transitation').focus().select();
										e.stopPropagation();
										e.preventDefault();
									}
									$cont++;
								});
							}
						}
					}

					e.preventDefault();
					return false;
				}
				
				if (code === 13 && e.shiftKey == true) {

					$cont = 0;
					$index= -1;
					
					var list = $(tr).find('td');

					if(list.length > 0){
						list.each(function( index ) {
							if(this == td[0]){
								$index = $cont;
							}
							$cont++;
						});
					}

					if($index >= 0){
						var tr = $(tr).prev();

						$cont = 0;
						var list = $(tr).find('td');

						if(list.length > 0){
							
							list.each(function( index ) {
								if($cont == $index){
									$(this).find('.atalho-input-tabela-transitation').focus().select();
									e.stopPropagation();
									e.preventDefault();
								}
								$cont++;
							});
						}
					}

					e.preventDefault();
					return false;
				}
			}
		});

		/*
		$(document).on('keyup', '.atalho-input-tabela', function (e) {
			var code = e.keyCode || e.which;

			var elmt = $(e.target);

			var is_input = $(e.delegateTarget.activeElement).is('input');
			if ( is_input ) {

				var tr = $(elmt).parent('tr');
				var td = $(elmt).parent('td');
				
				if (code === 9 && event.shiftKey == false) {

					var sair = true;
					while (sair) {
						var proximo = $(td).next();

						if(proximo.length > 0){
							var imp = $(proximo).find('.atalho-input-tabela');

							if(imp.length > 0){
								$(imp).focus();
								sair = false;
							}else{
								td = proximo;
							}
						}else{
							var trt = $(tr).next();
							if(trt.length > 0){
								td = $(trt).find('td');
							}else{
								sair = false;
							}
						}
					}

					e.preventDefault();
					return false;
				}
				
				if (code === 9 && event.shiftKey == true) {

					e.preventDefault();
					return false;
				}
			}
		});
		*/

	}

	/**
	 * 	Se um elemento é sobreposto pelo confirme
	 */
	function getBelowElements(el) {
		var elements = $('.modal-backdrop'); 
		var belowElements = [];
		
		for (var index = 0; index < elements.length; index++) {
			var currentElement = elements[index];

			if(
				(
					$(currentElement).hasClass('modal-backdrop') &&
					$(currentElement).hasClass('confirm') &&
					$(currentElement).hasClass('fade') &&
					$(currentElement).hasClass('in')
				)
			){
				belowElements.push(currentElement);
			}
						
		};

		var elements = $('#modal-historico:visible'); 
		
		for (var index = 0; index < elements.length; index++) {
			var currentElement = elements[index];
			belowElements.push(currentElement);			
		};

		if($('.modal-backdrop.confirm.fade.in:visible')){
			belowElements = [];			
		}

		return belowElements; // Retorna o array de elementos sobrepostos
	}

	/**
	 * Desabilitar submit a partir do 'Enter' nos formulários.
	 */
	function desabSubmitEnter() {

		$(document)
            .on('keypress', 'form', function (e) {
                var code = e.keyCode || e.which;

                var elmt = $(e.target);

                var is_textarea = $(e.delegateTarget.activeElement).is('textarea');
                if ( !is_textarea ) {
                    if (code === 13 && !elmt.is('[form-validate="true"]') ) {
                        e.preventDefault();
                        return false;
                    }
                }
            })
			.on('keydown', 'table tr[tabindex="0"]', 'down', function(e) {
				if(!$(e.currentTarget).find('input.edible').is(':focus')){
					e.preventDefault();
				}// else{
				// 	console.log('edible focado não prevenir o default')
				// }
                var scroll_parent = $(this).scrollParent();                                
			})
			.on('keydown', 'table tr[tabindex="0"]', 'up', function(e) {
				// e.preventDefault();
				if(!$(e.currentTarget).find('input.edible').is(':focus')){
					e.preventDefault();
				}	/*else{
					console.log('edible focado não prevenir o default')
				}*/                
                var scroll_parent = $(this).scrollParent();                
			});
		
	}
		
	/**
	 * Ativar clique do botão com 'Enter'.
	 */
	function ativarCliqueEnter() {
	
		$('button, select').bind('keydown', 'return', function() {
			$(this).click();
		});
		
	}
		
	/**
	 * Sair do sistema.
	 */
	function ativarAtalhoSair() {
		
		$('body, input, select').bind('keydown', 'alt+s', function() {
			$('#logout')[0].click();
		});
		
	}

	/** 
	 * Atalhos relacionados ao menu.
	 */
	function ativarAtalhoMenu() {
		
		//abrir/fechar menu
		$(document)
			.on('keydown', 'body, input[type="text"], textarea, select, #menu-filtro', 'alt+z', function() {
			
				if( $('.navbar-toggle').css('display') !== 'none' ) 
					$('.navbar-toggle').click();

			})
			.on('keydown', 'body, input[type="text"], textarea, select, #menu-filtro', 'pause', function() {
			
				if( $('.navbar-toggle').css('display') !== 'none' ) 
					$('.navbar-toggle').click();
			});
		
		/**
		 * Abrir submenu de acordo com o item passado.
		 * @param {int} submenu
		 */
//		function abrirSubmenu(submenu) {
//			
//			if ( $('#menu').hasClass('aberto') ) {
//				
//				$('#menu-itens button:nth-child('+submenu+')')
//					.click();
//				
//				setTimeout(function() { 
//					$('#menu-filtro-itens ul li:first-child a').focus(); 
//				}, 500);
//			}
//		}
//		
//		//abrir submenu Admin
//		$('body, #menu-filtro').bind('keydown', 'alt+1', function() {
//			abrirSubmenu(1);
//		});
		
	}
	
	/**
	 * Passar entre itens com as teclas up/down.
	 */
	function ativarTabSeta() {

		$(document)
			.on('keydown', 
				'.container-fluid',
				function(event) {
					if(event.which == 33){
							
					}
				}
			)
			.on('keydown', function(event) {
				
				if(event.which == 36 && event.shiftKey == false){

					if( $('.modal').is(':visible') ) {
						
						var elements = $('.modal:visible').last().find('table tbody tr:visible');
						var element  = [];
						
						var primeiro = 0;
						for (var index = 0; index < elements.length; index++) {
							if(elements[index].offsetHeight > 0 && elements[index].offsetWidth > 0 && primeiro == 0){
								primeiro = 1;
								element.push(elements[index]);
							}							
						}

						if(element.length > 0){
							$(element[0]).focus();
						}

					}else{
						var elements = $('table tbody tr:visible');
						var element  = [];
						//console.log(elements);
						var primeiro = 0;
						for (var index = 0; index < elements.length; index++) {
							if(elements[index].offsetHeight > 0 && elements[index].offsetWidth > 0 && primeiro == 0){
								primeiro = 1;
								element.push(elements[index]);
							}							
						}

						if(element.length > 0){
							$(element[0]).focus();
						}
					}
				}else if(event.which == 106 && event.ctrlKey == true && event.altKey == false){
					// else if(event.which == 106 && event.ctrlKey == true && event.altKey == false){
					// 	console.log('atalho p/ filtragem rápida');
					// 	// var a = $(this).closest('input.filtro_tabelaJS:visible:focusable');
					// 	var a = $(this).parent().parent().parent().prev('div.filtro_visibleJS').find('input.filtro_tabelaJS').first().focus()
					// 	if(a.length > 0){
					// 		a.first().focus();
					// 	}						
					// }
					if($(event.currentTarget).not('tr')){
						if( $('.modal').is(':visible') ) {
						
							var elements = $('.modal:visible').last().find('table:visible');
							var element  = [];						
							var primeiro = 0;
							for(var index = 0; index < elements.length; index++) {
								if(primeiro == 0){
									var filtro = $(elements[index]).parent().prev('div.filtro_visibleJS').find('input.filtro_tabelaJS');
									primeiro = 1;
									element.push(filtro);													
								}									
							}
	
							if(element.length > 0){
								// console.log('filtro focado 1');
								$(element[0]).focus();
							}
	
						}else{
							var elements = $('table:visible');
							var element  = [];
							//console.log(elements);
							var primeiro = 0;
							for (var index = 0; index < elements.length; index++) {
								if(primeiro == 0){
									var filtro = $(elements[index]).parent().prev('div.filtro_visibleJS').find('input.filtro_tabelaJS');
									if(filtro.length > 0){
										primeiro = 1;
										element.push(filtro);
									}									
								}							
							}
	
							if(element.length > 0){
								// console.log('filtro focado 2');
								$(element[0]).focus();
							}else{							
								var c = $('ul.list-inline.acoes');
								var wy = $('fieldset.programacao');
								if(c.length > 0){
									var d = c.find('button > span.caret');
									if(d.length > 0){
										var e = d.parent();										
										if(e.length > 0){
											e.first().click();
											var filtro132 = $(e.attr('data-target'))
											if(filtro132.length > 0){
												if(filtro132.attr('aria-expanded') == 'true'){
													var fielrd  = filtro132.find('input:text:focusable');
													var sealrch = filtro132.find('input[type="search"]');
													if(sealrch.length > 0){
														if(sealrch.length > 0){
															setTimeout(function(){
																sealrch.first().focus();
															}, 300);
														}														
													}else{
														if(fielrd.length > 0){
															setTimeout(function(){
																fielrd.first().focus();
															}, 300);
														}														
													}													
												}else{
													var y = $("table.tabelaJS").first().find('tr.selected:focusable');
													if(y.length > 0){
														y.first().focus();
													}
												}
											}
										}
									}
								}else if(wy.length > 0){
									var d = wy.find('button > span.caret');
									if(d.length > 0){
										var e = d.parent();										
										if(e.length > 0){
											e.first().click();
											var filtro132 = $(e.attr('data-target'))
											if(filtro132.length > 0){
												if(filtro132.attr('aria-expanded') == 'true'){
													var fielrd  = filtro132.find('input:text:focusable');
													var sealrch = filtro132.find('input[type="search"]');
													if(sealrch.length > 0){
														if(sealrch.length > 0){
															setTimeout(function(){
																sealrch.first().focus();
															}, 300);
														}														
													}else{
														if(fielrd.length > 0){
															setTimeout(function(){
																fielrd.first().focus();
															}, 300);
														}														
													}													
												}else{
													var y = $("table.tabelaJS").first().find('tr.selected:focusable');
													if(y.length > 0){
														y.first().focus();
													}
												}
											}
										}
									}
								}								
							}
						}
					}
					

				}

			})
			.on('keydown', 
				'table tbody tr',
				function(event) {
					if(event.which == 13){
						if( $(this).closest('table').hasClass('tab-enter') == false && $(this).closest('table').hasClass('notClick') == false){
							$(this).click();
						}else if($(this).closest('table').hasClass('tab-enter') == true){
							var impt = $($(this).next()[0]).find('input');
								impt = $(impt).not(".not-focus-enter");
								impt = $(impt).not(".atalho-input-tabela-enter");
							  $(impt).first().focus();
						}
					}else if(event.which == 106 && event.ctrlKey == true && event.altKey == false){
						// console.log('atalho p/ filtragem rápida');
						// var a = $(this).closest('input.filtro_tabelaJS:visible:focusable');
						if(!$('.modal').is(':visible') ) {							
							var a = $(this).parent().parent().parent().prev('div.filtro_visibleJS').find('input.filtro_tabelaJS').first().focus();
							if(a.length > 0){
								a.first().focus();
							}else{							
								var b = $(this).parent().parent().parent().parent();
								if(b.length > 0){
									var c = b.parent().parent().find('ul.list-inline.acoes');
									if(c.length > 0){
										var d = c.find('button > span.caret');
										if(d.length > 0){
											var e = d.parent();										
											if(e.length > 0){
												e.first().click();
												var filtro132 = $(e.attr('data-target'))
												if(filtro132.length > 0){
													if(filtro132.attr('aria-expanded') == 'true'){
														var fielrd = filtro132.find('input:text:focusable');
														var sealrch = filtro132.find('input[type="search"]');
														if(sealrch.length > 0){
															if(sealrch.length > 0){
																setTimeout(function(){
																	sealrch.first().focus();
																}, 300);
															}														
														}else{
															if(fielrd.length > 0){
																setTimeout(function(){
																	fielrd.first().focus();
																}, 300);
															}														
														}														
													}else{
														var y = $("table.tabelaJS").first().find('tr.selected:focusable');
														if(y.length > 0){
															y.first().focus();
														}
													}
													// var fielrd = filtro132.find('input:text:focusable');
													// if(fielrd.length > 0){
													// 	if(fielrd.attr('aria-expanded') == 'true'){
													// 		setTimeout(function(){
													// 			fielrd.first().focus();
													// 		}, 300);
													// 	}													
													// }
												}
											}
										}
									}
								}
							}	
						}					
					}
				}
			)
			.on('keydown', 
				'table tbody tr',
                
				'down',
				function(e) {
				
					var res = true;

                    if ( $(this).hasClass('vs-repeat-repeated-element') && (! $($(this).next()[0]).hasClass('vs-repeat-repeated-element')) ) {
                        res = false;
                    } 
                    else if ( $(this).is(':last-of-type') ) {
                        res = false;
                    }
                    else if ( e.target.tagName == 'INPUT' ) {
                    	res = false;
                    }
					
					if($(this).closest('table').hasClass('tabelaJS') == false){
						if( $(this).closest('table').hasClass('tab-enter') == false){
							// console.log('evento do hotkey aconteceu primeiro')
							$($(this).next()[0]).focus();
						}else{						
							$($(this).next()[0]).focus();
							$($(this).next()[0]).find('input').first().focus();
						}
					}else{
						if( ($(this).closest('input.edible')).length == 0){
							if(!$(this).find('input.edible').is(':focus')){
								$($(this).next()[0]).focus();
							}/*else{
								console.log('input focado não deve pular linha')
							}*/														
						}else{
							$($(this).next()[0]).focus();
						}
					}
						
                    //if (res)
                    	//$.tabNext();
				}
			)
			.on('keydown', 
				'table tbody tr', 
                
				'up', 
				function(e) {
					
					var res = true;

                    if ( $(this).hasClass('vs-repeat-repeated-element') && (! $($(this).prev()[0]).hasClass('vs-repeat-repeated-element')) ) {
                        res = false;
                    }
                    else if ( $(this).is(':first-of-type') ) {
                        res = false;
                    }
                    else if ( e.target.tagName == 'INPUT' ) {
                    	res = false;
                    }
					
					if($(this).closest('table').hasClass('tabelaJS') == false){
						if( $(this).closest('table').hasClass('tab-enter') == false ){
							$($(this).prev()[0]).focus();
						}else{
							$($(this).prev()[0]).focus();
							$($(this).prev()[0]).find('input').first().focus();
						}
					}else{
						if( ($(this).closest('input.edible')).length == 0){
							if(!$(this).find('input.edible').is(':focus')){
								$($(this).prev()[0]).focus();
							}/*else{
								console.log('input focado não deve pular linha')
							}*/														
						}else{
							$($(this).prev()[0]).focus();
						}
					}

                    //if (res)                    
                    	//$.tabPrev();
				}
			)
			.on('keydown', 
	
				'#menu-filtro, \n\
				 .consulta-descricao,\n\
				 #menu-filtro-itens ul li a,\n\
				 .lista-consulta ul li a',
		
				'down',
				function() {
				
					if( !$(this).parent().is(':last-of-type') )
						$.tabNext();
					
					return false;
				}
			)
			.on('keydown', 
				'#menu-filtro-itens ul li a, \n\
				 .lista-consulta ul li a', 
        
				'up', 
				function() {
					
					$.tabPrev();	
                    
					return false;
				}
			);
			
	}
    
    function validarAcao(tecla){

		var menu_open = $('#menu').hasClass('aberto');
		var atalhos_permitidos = ['alt+n','alt+g','alt+c', 'alt+u', 'alt+m', 'alt+s', 'alt+z','esc'];
        
		var anula_acao_padrao = false;
		var anula_acao = false;

		if(
			tecla.indexOf("f1")  >= 0 ||
			tecla.indexOf("f2")  >= 0 ||
			tecla.indexOf("f3")  >= 0 ||
			tecla.indexOf("f4")  >= 0 ||
			tecla.indexOf("f5")  >= 0 ||
			tecla.indexOf("f6")  >= 0 ||
			tecla.indexOf("f7")  >= 0 ||
			tecla.indexOf("f8")  >= 0 ||
			tecla.indexOf("f9")  >= 0 ||
			tecla.indexOf("f10") >= 0 ||
			tecla.indexOf("f11") >= 0 ||
			tecla.indexOf("f12") >= 0
		){
			anula_acao_padrao = true;
		}

		if(menu_open){
			if(atalhos_permitidos.indexOf(tecla) < 0){
				anula_acao = true;
			}
		}
		if($('div.carregando-pagina').css('display') == 'block'){
			anula_acao_padrao = true;
			anula_acao = true;
		}		
        
		if(anula_acao == false){
			// console.log('executar atalho '+tecla);			
			if($('.modal:visible').length > 0){
				if(menu_open == false){
					var objetos = $('.modal:visible').last().find('[data-hotkey="'+tecla+'"]:enabled');
					if(objetos.length == 0){
						var objetos = $('.modal:visible').last().find('[data-hotkey="'+tecla+'"]:focusable');
					}
				}else{
					var objetos = $('[data-hotkey="'+tecla+'"]:enabled');
				}				
			}else{
				var objetos = $('[data-hotkey="'+tecla+'"]:enabled');
				if(objetos.length == 0){
					var objetos = $('[data-hotkey="'+tecla+'"]:focusable');
				}
			}
	
			var i = 0;
			for (i = 0; i < $(objetos).length; i++) {
				
				var obj  = $(objetos)[i];
				var conf = getBelowElements(obj);
				
				if( ($(obj).is(':visible') || ($(obj).hasClass('btn-alpha')))){
					
					if(conf.length == 0){
						if ( $(obj).is(':focusable') && $(obj).attr('no-focus') == undefined ) {
							$(obj).focus();
						}
						
						anula_acao_padrao = true;
						
						if($('.carregando-pagina:visible').length == 0){
							obj.click();
						}						
					}else{
						anula_acao_padrao = true;	
					}
					
				}
			}

		}
		
        if ( anula_acao_padrao ) {
            return false;
        }
    }
		
	/** 
	 * Atalhos gerais.
	 */
	function ativarAtalhoGeral() {

		var recebe_acao = 'body, .btn, select, input, textarea, #menu-filtro';
		// $(document)
		// 	.on('keypress', 'select > option', 'enter', function() {
		// 		return validarAcao('enter');
		// 	});

		$(document)
			.on('keydown', recebe_acao, 'alt+1', function() {
				return validarAcao('alt+1');
			})
            .on('keydown', recebe_acao, 'alt+2', function() {
				return validarAcao('alt+2');
			})
            .on('keydown', recebe_acao, 'alt+3', function() {
				return validarAcao('alt+3');
			})
            .on('keydown', recebe_acao, 'alt+4', function() {
				return validarAcao('alt+4');
			})
            .on('keydown', recebe_acao, 'alt+5', function() {
				return validarAcao('alt+5');
			})
            .on('keydown', recebe_acao, 'alt+6', function() {
				return validarAcao('alt+6');
			})
			.on('keydown', recebe_acao, 'alt+7', function() {
				return validarAcao('alt+7');
			})
			.on('keydown', recebe_acao, 'alt+8', function() {
				return validarAcao('alt+8');
			})
			.on('keydown', recebe_acao, 'alt+9', function() {
				return validarAcao('alt+9');
			})
			.on('keydown', recebe_acao, 'alt+0', function() {
				return validarAcao('alt+0');
            })        
			.on('keydown', recebe_acao, 'f1', function() {
				return validarAcao('f1');
			})
            .on('keydown', recebe_acao, 'f2', function() {
				return validarAcao('f2');
			})
            .on('keydown', recebe_acao, 'f3', function() {
				return validarAcao('f3');
			})
            .on('keydown', recebe_acao, 'f4', function() {
				return validarAcao('f4');
			})
//            .on('keydown', recebe_acao, 'f5', function() {
//				return validarAcao('f15');
//			})
            .on('keydown', recebe_acao, 'f6', function() {
				return validarAcao('f6');
			})
			.on('keydown', recebe_acao, 'f7', function() {
				return validarAcao('f7');
			})
			.on('keydown', recebe_acao, 'f8', function() {
				return validarAcao('f8');
			})
			.on('keydown', recebe_acao, 'f9', function() {
				return validarAcao('f9');
			})
			.on('keydown', recebe_acao, 'f10', function() {
				return validarAcao('f10');
			})
			.on('keydown', recebe_acao, 'f11', function() {
				
				/* A solução do bug está no Controller da tela 22310 como uma função chamada vm.CADUnidProdutivas.PREVENT_ESC()*/
				
				if(key_detect_f11 == 0){

					key_detect_f11 = 1;

					setTimeout(function(event){

					  key_detect_f11 = 0;
						//se o visualizador de pdf estiver visível
						if ( $('.pdf-ver').is(':visible') )
						$('.pdf-ver .pdf-fechar')[0].click();
					
						//se o visualizador de arquivo estiver visível
						else if ( $('.visualizar-arquivo').is(':visible') )
							$('.esconder-arquivo')[0].click();
						
						//se o popup estiver visível
						else if ( $('.popup').is(':visible') && $('.popup .btn-voltar').length > 0 )
							$('.popup .btn-voltar')[0].click();

						//se o modal estiver visível
						else if ( $('.modal').is(':visible') ) {

							// esconde o último modal visível
							$('.modal:visible')
								.last()
								.find('.btn-voltar:visible')
								.click();

							// esconde o último modal visível
							// $('.modal:visible')
							// 	.last()
							// 	.find('.btn-cancelar:visible')
							// 	.click();
						}

						//voltar padrão
						else if ( $('ul.acoes .btn-voltar:visible').length > 0 )
							$('ul.acoes .btn-voltar:visible')[0].click();
						
						//cancelar
						// else if ( $('.btn-cancelar[data-hotkey="f11"]:visible').length > 0 )
							// $('.btn-cancelar[data-hotkey="f11"]:visible')[0].click();
						//voltar
						else if ( $('[data-hotkey="f11"]').is(':visible') ){
							validarAcao('f11');
						}
						//return false;
						},500);
						
					event.stopPropagation();
					event.preventDefault();
				}else{
					var keyCode_f11 = (event.keyCode ? event.keyCode : event.which);
				
					if(keyCode_f11 == 122){
						event.stopPropagation();
						event.preventDefault();
					}	
				}

			})
			.on('keydown', recebe_acao, 'f12', function() {
				return validarAcao('f12');
			})
			.on('keydown', recebe_acao, 'alt+a', function() {
				return validarAcao('alt+a');
			})
			.on('keydown', recebe_acao, 'alt+b', function() {
				return validarAcao('alt+b');
			})
			.on('keydown', recebe_acao, 'alt+c', function() {
				return validarAcao('alt+c');
			})
			.on('keydown', recebe_acao, 'alt+d', function() {
				return validarAcao('alt+d');
			})
			.on('keydown', recebe_acao, 'alt+e', function() {
				//$('[data-hotkey="alt+e"]')[0].click();  
				return false;
			})
			.on('keydown', recebe_acao, 'alt+f', function() {
				return validarAcao('alt+f');
			})
			.on('keydown', recebe_acao, 'alt+shift+f', function() {
				return validarAcao('alt+shift+f');
			})
			.on('keydown', recebe_acao, 'alt+shift+d', function() {
				return validarAcao('alt+shift+d');
			})
			.on('keydown', recebe_acao, 'alt+g', function() {
				return validarAcao('alt+g');
			})
			.on('keydown', recebe_acao, 'alt+h', function() {
				return validarAcao('alt+h');
			})
			.on('keydown', recebe_acao, 'alt+i', function() {
				return validarAcao('alt+i');
			})
			.on('keydown', recebe_acao, 'alt+j', function() {
				return validarAcao('alt+j');
			})
			.on('keydown', recebe_acao, 'alt+k', function() {
				return validarAcao('alt+k');
			})
			.on('keydown', recebe_acao, 'alt+l', function() {
				return validarAcao('alt+l');
			})
			.on('keydown', recebe_acao, 'alt+m', function() {
				return validarAcao('alt+m');
			})
			.on('keydown', recebe_acao, 'alt+n', function() {
				return validarAcao('alt+n');
			})
			.on('keydown', recebe_acao, 'n', function() {
				return validarAcao('n');
			})
			.on('keydown', recebe_acao, 'alt+o', function() {
				return validarAcao('alt+o');
			})
			.on('keydown', recebe_acao, 'alt+p', function() {
				return validarAcao('alt+p');
			})
			.on('keydown', recebe_acao, 'alt+q', function() {
				return validarAcao('alt+q');
			})
			.on('keydown', recebe_acao, 'alt+r', function() {
				return validarAcao('alt+r');
			})
			.on('keydown', recebe_acao, 'alt+s', function() {
				return validarAcao('alt+s');
			})
			.on('keydown', recebe_acao, 's', function() {
				return validarAcao('s');
			})
			.on('keydown', recebe_acao, 'alt+t', function() {
				return validarAcao('alt+t');
			})
			.on('keydown', recebe_acao, 'alt+u', function() {
				return validarAcao('alt+u');
			})
			.on('keydown', recebe_acao, 'alt+v', function() {
				return validarAcao('alt+v');
			})
			.on('keydown', recebe_acao, 'alt+w', function() {
				return validarAcao('alt+w');
			})
			.on('keydown', recebe_acao, 'alt+x', function() {
				return validarAcao('alt+x');
			})
			.on('keydown', recebe_acao, 'alt+y', function() {
				return validarAcao('alt+y');
			})
			.on('keydown', recebe_acao, 'alt+z', function() {
				return validarAcao('alt+z');
			})
			.on('keydown', recebe_acao, 'alt+f10', function() {
				return validarAcao('alt+f10');
			})
			.on('keydown', recebe_acao, 'alt+f11', function() {
				return validarAcao('alt+f11');
			})
			.on('keydown', recebe_acao, 'alt+left', function() {
				return validarAcao('alt+left');
			})
			.on('keydown', recebe_acao, 'alt+right', function() {
				return validarAcao('alt+right');
			})
			.on('keydown', recebe_acao, 'alt+up', function() {
				return validarAcao('alt+up');
			})
			.on('keydown', recebe_acao, 'alt+down', function() {
				return validarAcao('alt+down');
			})
			.on('keydown', recebe_acao, 'ctrl+left', function() {
				return validarAcao('ctrl+left');
			})
			.on('keydown', recebe_acao, 'ctrl+right', function() {
				return validarAcao('ctrl+right');
			})
			.on('keydown', recebe_acao, 'ctrl+up', function() {
				return validarAcao('ctrl+up');
			})
			.on('keydown', recebe_acao, 'ctrl+down', function() {
				return validarAcao('ctrl+down');
			})
			.on('keydown', recebe_acao, 'left', function() {
				return validarAcao('left');
			})
			.on('keydown', recebe_acao, 'right', function() {
				return validarAcao('right');
			})
			.on('keydown', recebe_acao, 'up', function() {
				return validarAcao('up');
			})
			.on('keydown', recebe_acao, 'down', function() {
				return validarAcao('down');
			})
			.on('keydown', recebe_acao, 'home', function() {
				if( !$('select, input, textarea').is(':focus') ) {
					return validarAcao('home');
				}
			})
			.on('keydown', recebe_acao, 'end', function() {
				if( !$('select, input, textarea').is(':focus') ) {
					return validarAcao('end');
				}
			})
            .keypress(function(event) {
                if($('.modal.fade.in.confirm.carregado').is(':visible')){
                    var keycode = event.keyCode || event.which;
                    if(keycode == '13') {

						var i = 0;
						var listaBtns = $('[data-hotkey="enter"]');

						var ja_executou = 0;

						for (i = listaBtns.length; i >= 0; i--) {

							if(ja_executou == 0){
								var obj    = listaBtns[i];
								var esdras = $('.input_consultaJS_filtro');
								var block  = false;

								for(var index_st = 0; index_st < esdras.length; index_st++) {
									var elementf = esdras[index_st];

									var focavel = $(elementf).is(":focus");
									var readonl = $(elementf).is("[readonly]");

									if( focavel &&  readonl == false){
										block = true
									}
									
								}

								var focavel = $(obj).is(":focusable");
								var readonl = $(obj).is("[readonly]");
								var visivel = $(obj).is(":visible");

								if( focavel && visivel && (block) == false && readonl == false){
									$(obj).click();
									ja_executou = 1;
								}
							}
						}
                    }
                }
            }).on('keydown', recebe_acao, 'esc', function(event) {
				///console.log('apertei esc!');
				/* A solução do bug está no Controller da tela 22310 como uma função chamada vm.CADUnidProdutivas.PREVENT_ESC()
				*/
				//console.log('apertei esc!');
				/*var key_detect = 0;
				if(key_detect == 0){
					key_detect = 1;
					
					setTimeout(function(event){
					key_detect = 0;
					},1000);
					
				}else{
				
					var keyCode = (event.keyCode ? event.keyCode : event.which);
				
					if(keyCode == 27){
						event.stopPropagation();
						event.preventDefault();
					}
					
				}*/

                if($('.modal.fade.in.confirm.carregado').is(':visible')){
					var i = 0;
					
					var elements = $('.modal.fade.in.confirm.carregado:visible').last();

                    for (i = 0; i < $(elements).find('[data-hotkey="esc"]').length; i++) {
						var obj = $(elements).find('[data-hotkey="esc"]')[i];
						
                        if($(obj).is(':visible')){
							event.stopPropagation();
							$(obj).click();
							$(".stop_propag_esc").val('false');	
                        }
					}
					
                }else{
                
					//fecha alerta
					if( $('.alert-principal').is(':visible') ) 
						$('.alert-principal').slideUp();

					//fecha filtro de menu
					else if( $('#menu-filtro-resultado').hasClass('ativo') ) 
						$('#menu-fechar').click();

					//fecha menu
					else if( $('#menu').hasClass('aberto') ) 
						$('.navbar-toggle').click();

					//se o visualizador de pdf estiver visível
					else if ( $('.pdf-ver').is(':visible') )
						$('.pdf-ver .pdf-fechar')[0].click();

					//se o visualizador de arquivo estiver visível
					else if ( $('.visualizar-arquivo').is(':visible') )
						$('.esconder-arquivo')[0].click();

					//fecha modal
					else if( $('.modal').is(':visible') ) {
						//console.log(this);
						
						//adicionar os valores dos botões a variáveis e bloquear a propagação do evento
						var voltar   = $('.modal:visible').last().find('.btn-voltar:visible');
						var cancelar = $('.modal:visible').last().find('.btn-cancelar:visible');
						
						var stop_propag = $(".stop_propag_esc").val() == 'true';
						
						//se encontrado o botão executar o click e o stopPropagation
						
						if(voltar.length > 0 && stop_propag == false){
							var dismiss  = voltar.attr('data-dismiss');
							var ng_click = voltar.attr('ng-click');
							var onclick  = voltar.attr('onclick');
							
							if((!dismiss) && (!ng_click) && (!onclick)){
								//console.log($(voltar).closest('.modal').attr('id'));
								var id_modal = $(voltar).closest('.modal').attr('id');
								$('#'+id_modal).modal('hide');
								//$(voltar).closest('.modal').prev('.modal-backdrop').css({"display":"none"});
								//$(voltar).closest('.modal').hide();
							}else{
								if($('.carregando-pagina:visible').length == 0){
									voltar.click();
								}
								event.stopPropagation();	
							}
							
						}

						if(cancelar.length > 0 && stop_propag == false){
							if($('.carregando-pagina:visible').length == 0){
								cancelar.click();
							}
							event.stopPropagation();

						}
						
						$(".stop_propag_esc").val('false');	
						
						// Esconde o último modal visível.
						/*$('.modal:visible')
							.last()
							.find('.btn-cancelar:visible')
							.click();*/

					} else if ( (!$('.modal').is(':visible')) && ($('[data-hotkey="esc"]').is(':visible')) ) {
							var i = 0;
							var lista_click = [];
							for(i = 0; i < $('[data-hotkey="esc"]').length; i++) {
								var obj = $('[data-hotkey="esc"]')[i];
								if($(obj).is(':visible')){
									lista_click.push(obj);
								}
							}

							for (i = 0; i < lista_click.length; i++){
								var obj = lista_click[i];
								$(obj).click();
							}

						/*
							var i = 0;

							for (i = 0; i < $('[data-hotkey="esc"]').length; i++) {
								var obj = $('[data-hotkey="esc"]')[i];
								if($(obj).is(':visible')){
									console.log('modal não visível e apertei o esc');
									$(obj).click();
								}
							}
						*/
					}
				
					//se o popup estiver visível
					//else if ( $('.popup').is(':visible') && $('.btn-voltar.popup-close').length > 0 )
					//	$('.btn-voltar.popup-voltar')[0].click();
					
					else if($('.popup').is(':visible')){
						
						if($('.modal.fade.in.confirm.carregado').is(':visible')){
							//se confirmação estiver ativa
						}else{
							if($('.popup-voltar').length > 0 ){
								
								if($('.popup-voltar').is(':visible')){
									$('.popup-voltar').trigger( "click" );
								}
								
							}else{
								
								if($('.popup-voltar2').length > 0 ){
									if($('.popup-voltar2').is(':visible')){
										$('.popup-voltar2').trigger( "click" );
									}
								
								}else{
									if($('.popup-close').attr('oculto') != 1){
										$('.popup-close').trigger( "click" );
									}
								}

							}
							
						}

					}
				}
				
//				else {
//					$('.btn-voltar').trigger( "click" );
//				}                
                
                
                
                
            })
            .on('keydown', 
                'input',
                'del',
                function() {
//                    if ( $(this).prop('readonly') || $(this).prop('disabled') ) return false;
////                    $(this).val('').triggerHandler('change');
//                    return false;
                }
            )
		;
        
        $('[form-validade="true"]').keypress(function( event ) {
            if ( event.which == 13 ) {
                $(this).closest('form').find('button[type="submit"]').click();
            }
        });
                
		$(document).on('keydown', 'body', function(event) {
			if (event.keyCode == 74 && event.ctrlKey){
				console.log('Ignorando Ctrl+J');
				event.preventDefault();
				event.stopPropagation();
			}
        });

		$(document).on('keyup', 'body', function(event) {
			if (event.keyCode == 74 && event.ctrlKey){
				console.log('Ignorando Ctrl+J');
				event.preventDefault();
				event.stopPropagation();
			}
        });

        
        $(document)
                .on('keydown', 'body', function(e) {

                    var modal = $('#modal-atalho');
                    // Allow: backspace, delete, tab, escape, enter and ',' '-'
                    if ($.inArray(e.keyCode, [45]) !== -1) {
                        // modal.modal('show');
                        // setTimeout(function(){
                        //     modal.prev().css('z-index',99998);
                        // });
                    }

                    e.key = parseInt(e.key);

                    if ( modal.hasClass('in') && (e.key > -1 && e.key < 99) ) {
                        
                        e.preventDefault();
                        
                        var ancora = modal.find('[item-id="' + e.key + '"]');
                        var link   = ancora.attr('item-link');
                        
                        if ( link != undefined && link.length > 0 ) {
                            winPopUp(link,'atalho',{width:992,height:595});
                            modal.modal('hide');
                        }
					}
					
					if(e.keyCode == 9){
						// console.log('tab pressionado');
						var modal = $('.modal.in').not('.modal-Teste').last();
						if(modal.length > 0){
							if(e.shiftKey == true){								
								var first_child = modal.last().children().find(':focusable').first();
								var last_child = modal.last().children().find(':focusable').last();
								//console.log(e.target);
								if(e.target == first_child[0]){
									last_child.focus();
									e.preventDefault();
								}
							}else{
								var last_child = modal.last().find(e.target);
								//console.log(last_child);
								if(last_child.length == 0){
									// console.log('último elemento selecionado');
									var first_child = modal.last().children().find(':focusable').first();
									first_child.focus();
								}
							}							
							
						   //e.preventDefault();
						}						
					}
                });
	        
        

	}
	
	$(function() {

		desabSubmitEnter();
		ativarCliqueEnter();
		ativarAtalhoSair();
		ativarAtalhoMenu();
		ativarTabSeta();
		ativarAtalhoGeral();
		atalhoInputTabela();
		
	});
	
})(jQuery);