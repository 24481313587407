(function($) {

	//funções para alterar a cor de um objeto
    function RgbToHsv(r, g, b) {
        var min = Math.min(r, g, b),
            max = Math.max(r, g, b),
            delta = max - min,
            h, s, v = max;

        v = Math.floor(max / 255 * 100);
        if (max == 0) return { h: 0, s: 0, v: 0 };
        s = Math.floor(delta / max * 100);
        var deltadiv = delta == 0 ? 1 : delta;
        if( r == max ) h = (g - b) / deltadiv;
        else if(g == max) h = 2 + (b - r) / deltadiv;
        else h = 4 + (r - g) / deltadiv;
        h = Math.floor(h * 60);
        if( h < 0 ) h += 360;
        return { h: h, s: s, v: v }
    }
    
    function HsvToRgb(h, s, v) {
        h = h / 360;
        s = s / 100;
        v = v / 100;

        if (s == 0)
        {
            var val = Math.round(v * 255);
            return {r:val,g:val,b:val};
        }
        hPos = h * 6;
        hPosBase = Math.floor(hPos);
        base1 = v * (1 - s);
        base2 = v * (1 - s * (hPos - hPosBase));
        base3 = v * (1 - s * (1 - (hPos - hPosBase)));
        if (hPosBase == 0) {red = v; green = base3; blue = base1}
        else if (hPosBase == 1) {red = base2; green = v; blue = base1}
        else if (hPosBase == 2) {red = base1; green = v; blue = base3}
        else if (hPosBase == 3) {red = base1; green = base2; blue = v}
        else if (hPosBase == 4) {red = base3; green = base1; blue = v}
        else {red = v; green = base1; blue = base2};

        red = Math.round(red * 255);
        green = Math.round(green * 255);
        blue = Math.round(blue * 255);
        return { r: red, g: green, b: blue };
    } 
    
    function mustureCor(paleta,light){
        var fator = light;
        var temp = 0;
        var cor = parseInt(paleta);

        if(cor > fator){
            temp = cor - fator;
            cor  = cor - parseInt(temp / 2);
        }else{
           temp = fator - cor;
            cor  = cor + parseInt(temp / 2); 
        }
        
        return cor;
        
    }
    
    // escurece uma cor somando a com outra cor
    // elemento = classe id  e etc
    // light % de brilho
    function AppendColor(elemento,light) {
        $(elemento).each(function(i){
            
            if(light == 0){
                
                var img = $(this).css("background-image");
                
                if(img == 'none'){
                    $(this).css("background", $(this).attr('oldCor'));
                }
                
            }else{
                
                // obtem a cor em RGB do elemento
                var color = $(this).css("background-color");
                var img = $(this).css("background-image");
                
                $(this).attr('oldCor',color);
                
                if((color == 'rgba(0, 0, 0, 0)') || (color == 'rgb(255, 255, 255)') || img != 'none'){
                    
                }else{
                    color = color.replace(/[^0-9,]+/g, "");
                    var red = color.split(",")[0];
                    var gre = color.split(",")[1];
                    var blu = color.split(",")[2];

                    red = mustureCor(red,light);
                    gre = mustureCor(gre,light);
                    blu = mustureCor(blu,light);
                    
                    var rgb = { r: red, g: gre, b: blu };
                    
                    // converte rgb para hsv
                    //var hsv = RgbToHsv(red,gre,blu);

                    // converte hsv para rgb modificando `v`
                    //var rgb = HsvToRgb(hsv.h, hsv.s, light);

                    //seta a nova cor
                    color = "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";
                    $(this).css("background", color);
                    
                }
            }   
        });
    }
     
	/** 
	 * Coloca um elemento em tela cheia
	 * @param id string
	 * */
	function goFullscreen(id) {
        
        //AppendColor('button',100);

		var element = document.getElementById(id);
        
		if(element.requestFullscreen) {
            element.requestFullscreen();
          } else if(element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
          } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
          }
	}

	/** 
	 * Retira um elemento da tela cheia
	 * */
	function lowFullscreen() {
        
        //AppendColor('button',0);
        
		if(document.exitFullscreen) {
            document.exitFullscreen();
        } else if(document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if(document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    var funcFechar = function(){};

    $(document).on('click','.btn-fechar-modal-tela', function() {
        go_tela_aberto1 = false;
        go_tela_aberto2 = false;
        go_tela_aberto3 = false;
        go_tela_aberto4 = false;
        go_tela_aberto5 = false;
        go_tela_aberto6 = false;
        go_tela_aberto7 = false;
        go_tela_aberto8 = false;
        
        $('.modal-listamenu').modal('hide');
        $('#modal-abrir-tela').modal('hide');
        $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','block');
    });

    var timeCarregarTelaModal = setTimeout(function() {},100);
    var AbrindoTelaModal      = 0;

    function abrirTelaEmModal(titulo, url, eventClose){

        if(AbrindoTelaModal == 0){
            
            AbrindoTelaModal = 1;

            $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','block');

            funcFechar = function(){};
            if(eventClose){
                funcFechar = eventClose;
            }

            $('#titulo-modal-abrir-tela').html(titulo);

            var proj = $('.abrir_tela');
            proj.attr('src', url);

            $('#modal-abrir-tela').modal('show');

            try {
                clearTimeout(timeCarregarTelaModal);
            } catch (error) {}

            timeCarregarTelaModal = setTimeout(function() {
                $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','none');
                AbrindoTelaModal = 0;  
            },2000);

        }

    }

    function fechaTelaEmModal(){

        return new Promise(function(resolve, reject) {

            $('.modal-listamenu').modal('hide');
            $('#modal-abrir-tela').modal('hide');
            $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','block');

            $('#modal-abrir-tela').on('hidden.bs.modal', function () {
                funcFechar();
                resolve();
            });

        });

    }

    var go_tela_aberto1 = false;
    $(document).on('click','.go-calendario', function() {

        function executarA(){
            if(go_tela_aberto1 == false){
                go_tela_aberto1 = true;
                abrirTelaEmModal('Agendamentos','/_11620', function(){
                    go_tela_aberto1 = false;
                });
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto2 == true || go_tela_aberto3 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarA();
                },300);
            });
        }else{
            executarA();
        }

    });

    var go_tela_aberto2 = false;
    $(document).on('click','.go-ajuda', function() {
        
        var menu = $(this).data('menu');

        function executarB(){
            if(go_tela_aberto2 == false){
                go_tela_aberto2 = true;
                abrirTelaEmModal('Ajuda','/_11650?MENU='+menu, function(){
                    go_tela_aberto2 = false;
                });
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto1 == true || go_tela_aberto3 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto1 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarB();
                },300);
            });
        }else{
            executarB();
        }

    });

    var go_tela_aberto3 = false;
    $(document).on('click','.go-msg', function() {

        function executarC(){
            if(go_tela_aberto3 == false){
                go_tela_aberto3 = true;
                abrirTelaEmModal('Notificações','/_11670', function(){
                    go_tela_aberto3 = false;
                });
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto4 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarC();
                },300);
            });
        }else{
            executarC();
        }

    });

    $(document).on('click','.btn-fechar-menu-lista', function() {
        go_tela_aberto1 = false;
        go_tela_aberto2 = false;
        go_tela_aberto3 = false;
        go_tela_aberto4 = false;
        go_tela_aberto5 = false;
		go_tela_aberto6 = false;
		go_tela_aberto7 = false;
		go_tela_aberto8 = false;
        
        $('.modal-listamenu').modal('hide');
        $('#modal-abrir-tela').modal('hide');
        $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','block');
    });

    var go_tela_aberto4 = false;
    $(document).on('click','.go-rel', function() {

        var menu = $(this).data('menu');

        function executarD(){

            $('.corpo-modal-rel').html('');

            if(go_tela_aberto4 == false){
                go_tela_aberto4 = true;

                var type	= 'GET',
                    url		= '/_11081/relatorios?MENU='+menu,
                    data	= {'filtro': ''},
                    success	= function(data) {
                        $('.corpo-modal-rel').html(data);
                    };

                    execAjax2(type, url, data, success);
            
                $('.modal-listamenu').modal('show');
            }else{
                go_tela_aberto4 = false;
                $('.modal-listamenu').modal('hide');
            } 
        }

        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto3 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarD();
                },300);
            });
        }else{
            executarD();
        }

    });

    var go_tela_aberto5 = false;
    $(document).on('click', '.btn-responder-notificacao',function(){
        var id = $(this).data('id');
    
        function executarE(){
            go_tela_aberto5 = true;
            abrirTelaEmModal('Notificações','/_11670?ID='+id , function(){
                go_tela_aberto5 = false;
            });
        }
    
        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto3 == true || go_tela_aberto4 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarE();
                },300);
            });
        }else{
            executarE();
        }
    });

    var go_tela_aberto6 = false;
    $(document).on('click','.go-cham', function() {

        function executarF(){
            if(go_tela_aberto6 == false){
                go_tela_aberto6 = true;
                abrirTelaEmModal('Chamados','/_32050?DASHBOARD', function(){
                    go_tela_aberto6 = false;
                });
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto2 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
			go_tela_aberto5 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarF();
                },300);
            });
        }else{
            executarF();
        }

    });

	var go_tela_aberto7 = false;
	var go_tela_aberto7_modal = false;
    var legacy_bday = false;

    // LEGACY FUNCTION FOR EMPLOYEE BIRTHDAY LIST
    /*
    $(document).on('click','.go-niver', function(e) {
        function executarF(){
            if(go_tela_aberto7 == false){
                go_tela_aberto7 = true;
				
				var type = 'POST',
				url		= '/listarAniversarios',
				data	= {'filtro': ''},
				success	= function(response) {

					if(response.length > 0){
						var dias_niver = [];
						for(var index = 0; index < response.length; index++) {
							var element = response[index];
							dias_niver.push({DIA:element.DIA});
						}

						dias_niver = removeDupesInArray(dias_niver,'DIA');

						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = []
							for(var index2 = 0; index2 < response.length; index2++) {
								var element2 = response[index2];
								if(dias_niver[index].DIA == element2.DIA){
									dias_niver[index].ANIVERSARIANTES.push(element2);
								}
							}
						}

						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = sort_by_key(dias_niver[index].ANIVERSARIANTES,'COLABORADOR');
						}

						var html = '';

                        if(legacy_bday){    // ----------------------------------------------- Método legacy dos aniversariantes ----------------------------------------------- //

                            for(var index = 0; index < dias_niver.length; index++){

                                html = html+'<label style="font-size: 26pt; font-family: \'Gorditas\';"> DIA '+dias_niver[index].DIA+'</label>';

                                for(var index2 = 0; index2 < dias_niver[index].ANIVERSARIANTES.length; index2++) {
                                    var element2 = dias_niver[index].ANIVERSARIANTES[index2];
                                    html = html+'<br><label style="font-size: 16pt; font-family: \'Gorditas\';"> '+dias_niver[index].ANIVERSARIANTES[index2].COLABORADOR+(dias_niver[index].ANIVERSARIANTES[index2].SETOR !== '' ? ', '+dias_niver[index].ANIVERSARIANTES[index2].SETOR : '')+'</label>'
                                }

                                html = html+'<br>';

                            }

                            $('#text_aniversarios').html(html);

                            var param_niver = trim_null($('#_abrir_aniversario').val());
                            if(param_niver == 1){
                                $('.alterarAbrirAniversario').attr('checked',true);
                                // $('.alterarAbrirAniversario').css('display','inline-block');
                                // $('.alterarAbrirAniversario2').css('display','none');
                            }else{
                                $('.alterarAbrirAniversario').attr('checked',false);
                                // $('.alterarAbrirAniversario').css('display','none');
                                // $('.alterarAbrirAniversario2').css('display','inline-block');
                            }
    
                            setTimeout(function(){
                                $('#modal_aniversario').modal('show');
                                $('#modal_aniversario').css('z-index','99999');
                            },300);
                            
                            if(go_tela_aberto7_modal == false){
                                go_tela_aberto7_modal = true
                                $('#modal_aniversario').on('hidden.bs.modal', function () {
                                    go_tela_aberto1 = false;
                                    go_tela_aberto2 = false;
                                    go_tela_aberto3 = false;
                                    go_tela_aberto4 = false;
                                    go_tela_aberto5 = false;
                                    go_tela_aberto6 = false;
                                    go_tela_aberto7 = false;							
                                });
                            }	

                        } else {    // ----------------------------------------------- Método novo dos aniversariantes ----------------------------------------------- //

                            for(var index = 0; index < dias_niver.length; index++){

                                html = html+'<li>';

                                // Dia
                                html += '<div style="font-family: \'Segoe UI\';"> DIA '+dias_niver[index].DIA+'</div>';

                                // Foto de usuario
                                html += '<img src="../../../../assets/images/perfil.png" alt="" srcset="" width="70" height="70" style="float: left">'; 

                                // Informacoes dos aniversariantes
                                for(var index2 = 0; index2 < dias_niver[index].ANIVERSARIANTES.length; index2++) {

                                    var element2 = dias_niver[index].ANIVERSARIANTES[index2];

                                    html += '<div style="font-family: \'Segoe UI\';"> '+dias_niver[index].ANIVERSARIANTES[index2].COLABORADOR+'</div>';
                                    html += '<div style="font-family: \'Segoe UI\';"> '+(dias_niver[index].ANIVERSARIANTES[index2].SETOR !== '' ? ', '+dias_niver[index].ANIVERSARIANTES[index2].SETOR : '')+'</div>';

                                }

                                html = html + '</li>';
                                
                            }

                            // $('#lista_aniversariantes').html(html);

                            $('#_lista_aniversariantes').val(html);

                            var param_niver = trim_null($('#_abrir_aniversario').val());
                            
                            if(param_niver == 1){
                                $('.alterarAbrirAniversario').attr('checked',true);
                                // $('.alterarAbrirAniversario').css('display','inline-block');
                                // $('.alterarAbrirAniversario2').css('display','none');
                            }else{
                                $('.alterarAbrirAniversario').attr('checked',false);
                                // $('.alterarAbrirAniversario').css('display','none');
                                // $('.alterarAbrirAniversario2').css('display','inline-block');
                            }
    
                            setTimeout(function(){
                                $('#modal_aniversario').modal('show');
                                $('#modal_aniversario').css('z-index','99999');
                            },300);

                        }
	
					}else{

						if(e.originalEvent !== undefined){
						   showErro('Não há aniversariantes a serem exibidos!');
						}

						go_tela_aberto7 = false;		

					}	
                    				
				};

				execAjax1(type,url,data,
				function(resposta) {
					success(resposta)
				},
				function(xhr){
					reject(xhr);
				}); 
				
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto2 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
			go_tela_aberto5 = false;
			go_tela_aberto6 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarF();
                },300);
            });
        }else{
            executarF();
        }

	});
    */
	
	/*$(document).ready(function() {
		var fintar = $('#_abrir_aniversario').val();
		if(fintar == 1){
			setTimeout(function(){
				go_tela_aberto7 = true;				
					
					var type = 'POST',
					url		= '/listarAniversarios',
					data	= {'filtro': ''},
					success	= function(response) {
					
						var dias_niver = [];
						for(var index = 0; index < response.length; index++) {
							var element = response[index];
							dias_niver.push({DIA:element.DIA});
						}

						dias_niver = removeDupesInArray(dias_niver,'DIA');

						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = []
							for(var index2 = 0; index2 < response.length; index2++) {
								var element2 = response[index2];
								if(dias_niver[index].DIA == element2.DIA){
									dias_niver[index].ANIVERSARIANTES.push(element2);
								}
							}
						}

						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = sort_by_key(dias_niver[index].ANIVERSARIANTES,'COLABORADOR');
						}

						var html = '';

						for(var index = 0; index < dias_niver.length; index++){
							html = html+'<label style="font-size: 26pt; font-family: \'Gorditas\';"> DIA '+dias_niver[index].DIA+'</label>'
							for(var index2 = 0; index2 < dias_niver[index].ANIVERSARIANTES.length; index2++) {
								var element2 = dias_niver[index].ANIVERSARIANTES[index2];
								html = html+'<br><label style="font-size: 16pt; font-family: \'Gorditas\';"> '+dias_niver[index].ANIVERSARIANTES[index2].COLABORADOR+', '+dias_niver[index].ANIVERSARIANTES[index2].SETOR+'</label>'
							}
							html = html+'<br>';
						}

						$('#text_aniversarios').html(html);

						var param_niver = trim_null($('#_abrir_aniversario').val());
						if(param_niver == 1){
							$('.alterarAbrirAniversario').attr('checked',true);
							// $('.alterarAbrirAniversario').css('display','inline-block');
							// $('.alterarAbrirAniversario2').css('display','none');
						}else{
							$('.alterarAbrirAniversario').attr('checked',false);
							// $('.alterarAbrirAniversario').css('display','none');
							// $('.alterarAbrirAniversario2').css('display','inline-block');
						}

						setTimeout(function(){
							$('#modal_aniversario').modal('show');
							$('#modal_aniversario').css('z-index','99999');
						},300);
						
						if(go_tela_aberto7_modal == false){
							go_tela_aberto7_modal = true
							$('#modal_aniversario').on('hidden.bs.modal', function () {
								go_tela_aberto1 = false;
								go_tela_aberto2 = false;
								go_tela_aberto3 = false;
								go_tela_aberto4 = false;
								go_tela_aberto5 = false;
								go_tela_aberto6 = false;
								go_tela_aberto7 = false;							
							});
						}							
					};

					execAjax1(type,url,data,
					function(resposta) {
						success(resposta)
					},
					function(xhr){
						reject(xhr);
					});      
			},2000);	
		}
	});*/
	
    $(document).on('click','.alterarAbrirAniversario', function(){

		var valor = $('#alterarAbrirAniversario').is(':checked') == true ? 1 : 0;
				
		var type	= 'POST',
			url		= '/GravarParamAniversario',
			data	= {'VALOR': valor},
			success	= function(response) {
				var novo_valor = $('#alterarAbrirAniversario').is(':checked') == true ? 1 : 0;
				showSuccess('Alterações realizadas com sucesso!');
				$('#_abrir_aniversario').val(novo_valor);
			};

		execAjax1(type,url,data,
			function(resposta) {
				success(resposta)
			},
			function(xhr){
				//reject(xhr);
			});

	});
	
    $(document).on('click','.alterarAbrirNotificacao', function(){

		var valor = $('#alterarAbrirNotificacao').is(':checked') == true ? 1 : 0;
				
		var type	= 'POST',
			url		= '/GravarParamNotificacoes',
			data	= {'VALOR': valor},
			success	= function(response) {
				var novo_valor = $('#alterarAbrirNotificacao').is(':checked') == true ? 1 : 0;
				showSuccess('Alterações realizadas com sucesso!');
				$('#_abrir_notificacoes').val(novo_valor);
			};

		execAjax1(type,url,data,
			function(resposta) {
				success(resposta)
			},
			function(xhr){
				//reject(xhr);
			});

	});

    /** 
	 * chama o evento de fullscreen
     * @param xml e botão que foi clicado
	 **/
    var tela;
    var modo_tela = 0;

    document.onfullscreenchange = function ( event ) { 
        console.log(event);

        modo_tela = modo_tela == 0 ? 1 : 0;

        var btn  = $('.go-fullscreen');

        if(btn.length > 0){
            
            for(var index = 0; index < btn.length; index++) {
                var element = $('.go-fullscreen')[index];

                if(modo_tela == 0){
                    var old = $(element).attr('oldullscreen');
                    $(element).attr('gofullscreen',old);
                }else{
                    $(element).attr('gofullscreen','esc');
                }
            }
        }

        if(event.target.id == 'panel-modal'){
            var $body = $(".abrir_tela").contents().find("body");

            if($body){
                var top = $($body).find(".navbar-fixed-top");
                $(top).css('text-align','center');
                $(top).html(''+
                    '<span style="font-size: 17px;">Pressione "esc" para sair do modo tela cheia</span>'
                );
            }
        }

    }; 

    function tratarFull(){
        setTimeout(function(){
            $( ".go-fullscreen" ).each(function( index ) {
                var old = $($( ".go-fullscreen" )[index]).attr('gofullscreen');
                $($( ".go-fullscreen" )[index]).attr('oldullscreen',old );
            });    
        }, 500);
    }

    tratarFull();
    
	function eventoFullscreen(e) {
        
		var id = $(e).attr('gofullscreen');

		if (id === 'esc'){
            var old = $(e).attr('oldullscreen');
			$(e).attr('gofullscreen',old);
			lowFullscreen();
		}else{
			tela = id;
            goFullscreen(id);
            $(e).attr('gofullscreen','esc');
		}

	}
	
	$(function() {
	
		$(document).on('click','.go-fullscreen', function() {
			eventoFullscreen(this);
		});
        
        $(document).on('click','.a_resizable', function(e) {
            $('.obj_resizable').addClass('resizable_ativo');
            $('.a_resizable').addClass('d_resizable');
            $('.a_resizable').removeClass('a_resizable');
        });
        
        $(document).on('click','.d_resizable', function(e) {
            $('.obj_resizable').removeClass('resizable_ativo');
            $('.d_resizable').addClass('a_resizable');
            $('.d_resizable').removeClass('d_resizable');
        });
        
        //*
        $(document).on('click','.resizable_ativo', function(e) {

            var element = this;

            if(element){
                
                if(element.requestFullscreen) {
                    element.requestFullscreen();
                  } else if(element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                  } else if(element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                  } else if(element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                  }
            }
        });
		//*/
	});

    var go_tela_aberto1 = false;
    $(document).on('click','.go-avaliacao', function() {

        function executarA(){
            if(go_tela_aberto1 == false){
                go_tela_aberto1 = true;
                abrirTelaEmModal('Avaliação de Atendimento','/_11910', function(){
                    go_tela_aberto1 = false;
                });
            }else{
                fechaTelaEmModal();
            } 
        }

        if(go_tela_aberto2 == true || go_tela_aberto3 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true || go_tela_aberto8 == true){
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;
			go_tela_aberto8 = false;
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarA();
                },300);
            });
        }else{
            executarA();
        }

    });

    var go_tela_aberto8 = false;
    $(document).on('click','.go-conhecimento', function() {

        var menu = $(this).data('menu');

        function executarA(){
            if(go_tela_aberto8 == false){
                go_tela_aberto8 = true;
                abrirTelaEmModal('Bases de Conhecimento','/_11920?MENU='+menu, function(){
                    go_tela_aberto8 = false;
                });
            }else{
                fechaTelaEmModal();
            }
        }

        if(go_tela_aberto1 == true || go_tela_aberto2 == true || go_tela_aberto3 == true || go_tela_aberto4 == true || go_tela_aberto5 == true || go_tela_aberto6 == true || go_tela_aberto7 == true){
            go_tela_aberto1 = false;
            go_tela_aberto2 = false;
            go_tela_aberto3 = false;
            go_tela_aberto4 = false;
            go_tela_aberto5 = false;
			go_tela_aberto6 = false;
			go_tela_aberto7 = false;			
            fechaTelaEmModal().then( function(val) {
                setTimeout(function(){
                    executarA();
                },300);
            });
        }else{
            executarA();
        }

    });
    
})(jQuery);