/**
 * Script com variáveis globais.
 */

var $ = jQuery;
var hostname = window.location.hostname;
/**
 * • Caminho acessado<br/>
 * Ex.: Caminho acessado: http://localhost/_13030/create<br/>
 * Retorno: /_13030/dre
 * @type string
 */
var pathname            = window.location.pathname;
var urlhost             = document.location.origin;
var urlhash             = document.URL.substr(document.URL.indexOf('#')+1);
var grupo_empresarial   = '';
var path_dir_empresa    = '';

setTimeout(function(){
    grupo_empresarial   = $('#grupo-empresarial').val();  
    path_dir_empresa    = $('#path_dir_empresa').val();   
}, 200);

//valores padrões para DataTable
var table_default =
{
    scrollY  : "70vh", // Altura da tabela 
    scrollX  : true  , // Habilita a rolagem horizontal
    bSort    : false , // Desativa a ordenação
    bFilter  : false , // Desativa o filtro
    bInfo    : false , // Desativa as informações de registro
    bPaginate: false , // Desativa a paginação
    language : {emptyTable : "Não há registros para listar"}
};

var StringMask = require('string-mask');

var ieMasks = {
	'AC': [{mask: new StringMask('00.000.000/000-00')}],
	'AL': [{mask: new StringMask('000000000')}],
	'AM': [{mask: new StringMask('00.000.000-0')}],
	'AP': [{mask: new StringMask('000000000')}],
	'BA': [{chars: 8, mask: new StringMask('000000-00')}, {mask: new StringMask('0000000-00')}],
	'CE': [{mask: new StringMask('00000000-0')}],
	'DF': [{mask: new StringMask('00000000000-00')}],
	'ES': [{mask: new StringMask('00000000-0')}],
	'GO': [{mask: new StringMask('00.000.000-0')}],
	'MA': [{mask: new StringMask('000000000')}],
	'MG': [{chars: 13, mask: new StringMask('000.000.000/0000')}, {chars: 11, mask: new StringMask('00000000000')}],
	'MS': [{mask: new StringMask('000000000')}],
	'MT': [{chars: 9, mask: new StringMask('00.000.000-0')}, {mask: new StringMask('0000000000-0')}],
	'PA': [{mask: new StringMask('00-000000-0')}],
	'PB': [{mask: new StringMask('00000000-0')}],
	'PE': [{chars: 9, mask: new StringMask('0000000-00')}, {mask: new StringMask('00.0.000.0000000-0')}],
	'PI': [{mask: new StringMask('000000000')}],
	'PR': [{mask: new StringMask('000.00000-00')}],
	'RJ': [{mask: new StringMask('00.000.00-0')}],
	'RN': [{chars: 9, mask: new StringMask('00.000.000-0')}, {mask: new StringMask('00.0.000.000-0')}],
	'RO': [{mask: new StringMask('0000000000000-0')}],
	'RR': [{mask: new StringMask('00000000-0')}],
	'RS': [{mask: new StringMask('000/0000000')}],
	'SC': [{mask: new StringMask('000.000.000')}],
	'SE': [{mask: new StringMask('00000000-0')}],
	'SP': [{mask: new StringMask('000.000.000.000')}, {mask: new StringMask('-00000000.0/000')}],
	'TO': [{mask: new StringMask('00000000000')}, {mask: new StringMask('000000000')}]
};
