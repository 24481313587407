/**
 * WebSocket
 */
    
    var SocketWeb = {
        CONNECTION_ID_BKP : 0,
        CONNECTION        : null,
        CONNECTION_ID     : 0,
        QUERY_METHOD      : [],
        ERROR_EVENT       : null, 
        SUCCESS_EVENT     : null,
        SUCCESS_EVENT     : null,
        SILENT            : false,
        ERROS             : 0,
        timePing          : 0,
        setStatus: function(status){
            //0-não definido
            //1-conectado
            //2-desconectado
            var obj = $('.status-websocket');

            if(status == 1){
                $(obj).find('.conectado').css('display', 'block');
                $(obj).find('.desconectado').css('display', 'none');
            }else{
                $(obj).find('.conectado').css('display', 'none');
                $(obj).find('.desconectado').css('display', 'block');
            }
        },
        create : function (methods, redundancia = false) {

            var imp = $('#habilita-websocket');
            var habilita_websocket = $(imp).val();

            WEBSOCKET_SERVER_REDUNDANCIA = typeof WEBSOCKET_SERVER_REDUNDANCIA == 'undefined' ? '' : WEBSOCKET_SERVER_REDUNDANCIA;
            
            var that = this;
            var wsUri = redundancia && (WEBSOCKET_SERVER_REDUNDANCIA+'').length > 0 ? WEBSOCKET_SERVER_REDUNDANCIA : WEBSOCKET_SERVER;

            if(redundancia){
                $('#websocket_redundancia').val('1');
            }else{
                $('#websocket_redundancia').val('0');
            }

            if(habilita_websocket == 1){
                this.CONNECTION     = new WebSocket(wsUri);
                this.QUERY_METHOD   = methods;

                var restore_id = this.CONNECTION_ID_BKP;

                this.CONNECTION.onopen = function () {
                    that.ping();
                    that.setStatus(1);
                    that.ERROS = 0;

                    socketLog(that.CONNECTION);
                    that.sendMensage('DE','PARA','MSG','GETIDCOM','GETIDCOM','REQUEST', restore_id);

                    if (that.SUCCESS_EVENT != null) {
                        that.SUCCESS_EVENT();
                    }
                };

                // Log errors
                this.CONNECTION.onerror = function (error) {
                    socketLog(error);
                    that.onError(error);
                    //that = null;

                    that.setStatus(2);
                };

                // Log errors
                this.CONNECTION.onclose = function (error) {
                    socketLog(error);

                    that.setStatus(2);

                    if (that != null) {
                        that.onError(error);
                        //that = null;
                    }
                };

                // Log messages from the server
                this.CONNECTION.onmessage = function (e) {
                    socketLog(e);
                    obj = $.parseJSON(e.data);

                    socketLog(obj);

                    var CHANEL  = obj.CHANEL;
                    var MSG 	= obj.MENSAGE;
                    var VALIDE  = obj.VALIDE;

                    var TYPE    = obj.MENSAGE.TYPE;
                    var DADOS   = obj.MENSAGE.DADOS;
                    var METODO  = obj.MENSAGE.METODO;

                    if(VALIDE == true){

                        socketLog(obj);

                        if(METODO == 'GETIDCOM'){
                            
                            $('._socket_token').val(CHANEL);
                            that.CONNECTION_ID = CHANEL;

                            if(that.CONNECTION_ID_BKP == 0){
                                that.CONNECTION_ID_BKP = CHANEL;
                            }

                        }else{
                            if(METODO == 'PROGRESS'){
                                $('.progress-bar').css('transition','0.5s');
                                $('.progress-bar').css('background-color','rgba(169, 15, 15, 0.8);');

                                $('.progress-bar').css('width',DADOS);
                            }else{
                                that.onMensage(obj);
                            }
                        }


                    }else{
                        alert('Erro ao conectar');
                    }
                };
            }
        },
        addMetode : function(metodos){

            for (var index = 0; index < metodos.length; index++) {
                var item = metodos[index];
                this.QUERY_METHOD.push(item);  
            }
            
        },
        onError : function (error){

            this.ERROS++;

            if (this.ERROR_EVENT == null) {
                if(!this.SILENT){
                    if (error.type == 'close') {
                        showErro('Servidor WebSocket desconectado.');
                    }
                    else {
                        showErro('Erro no servidor WebSocket.')
                    }
                }
            }
            else {
                this.ERROR_EVENT(error);
            }
        },
        sendMensage : function (DE,PARA,MSG,TYPE,METODO,REQUEST, RESTORE_ID){

            var msg = {
                DE 		:   DE,
                PARA 	:   PARA,
                MENU   	:   $('meta[menu]').attr('content'),
                USER_ID :   $('#usuario-id').val(),
                MENSAGE	: {
                    TYPE	    : TYPE,
                    DADOS	    : MSG,
                    METODO	    : METODO,
                    REQUEST	    : REQUEST,
                    RESTORE_ID  : RESTORE_ID
                }
            };

            this.CONNECTION.send(JSON.stringify(msg));
        },
        onMensage : function (message) {
        
            for ( var i in this.QUERY_METHOD ) {

                var item = this.QUERY_METHOD[i];

                if (message.MENSAGE.METODO == item.METHOD ) {
                    item.FUNCTION ? item.FUNCTION(message) : null;
                }
            }

        },
        watchMethod : function (route, args, time) {
            
            var that = this;
            var si = setInterval(function(){
                
                if ( that.CONNECTION_ID > 0 ) {
                    
                    clearInterval(si);
                
                    var msg = {
                        DE 		:   that.CONNECTION_ID,
                        PARA 	:   that.CONNECTION_ID,
                        MENU   	:   $('meta[menu]').attr('content'),
                        USER_ID :   $('meta[user_id]').attr('content'),
                        MENSAGE	: {
                            TYPE	: 'WATCH_METHOD',
                            DADOS	: {
                                ROUTE : route,
                                ARGS : args,
                                TIME : time
                            },
                            METODO	: 'WATCH_METHOD',
                            REQUEST	: []
                        }
                    };
                
                    that.CONNECTION.send(JSON.stringify(msg));
                }
                
            },1000);
        },
        ping: function(){
            var that = this;

            clearInterval(that.timePing);
            var timePing = setInterval(function(){

                var msg = {
                    DE 		:   that.CONNECTION_ID,
                    PARA 	:   that.CONNECTION_ID,
                    MENU   	:   $('meta[menu]').attr('content'),
                    USER_ID :   $('meta[user_id]').attr('content'),
                    MENSAGE	: {
                        TYPE	: 'PING',
                        DADOS	: {
                            ROUTE: 0,
                            ARGS : 0,
                            TIME : 0
                        },
                        METODO	: 'PING',
                        REQUEST	: []
                    }
                };
            
                that.CONNECTION.send(JSON.stringify(msg));

            },25000);

            that.timePing = timePing;
        }
    };
    
    function SSE(event_source) {
        this.event_source = event_source;
        
        var that = this;
        var sse = null;
        
        if(typeof(EventSource) !== "undefined") {
            sse = new EventSource(event_source);
        } else {
            showErro('Opss... Ocorreu uma falha!<br/>Seu navegador não possui suporte para eventos dinâmicos.<br><b>Recomendamos a utilização do Google Chrome.</b><br/>Entre em contato com suporte técnico para esclarecer demais dúvidas.');
        }

        sse.onmessage = function(e) {
            that.onmessage(e);
        };
        
        sse.onerror = function(e) {
            that.onerror(e);
            showErro('Opss... Ocorreu uma falha!<br/>Os eventos dinâmicos foram desconectados.<br/><b>Estamos tentando reconectar automaticamente...</b><br/>Se o erro pesistir, entre em contato com suporte técnico.');
//            $('.baloes .balao').addClass('balao-fixo');
//            sse.close();
            
        };
    }
    
    SSE.prototype.onmessage = function(){};
    SSE.prototype.onerror = function(){};
    
    function socketLog(msg) {
        if (WEBSOCKET_CONSOLE && SocketWeb.SILENT == false) {
            console.log(msg);
        }
    }

    var PostMessageServer = {
        METODOS : [],
        addMetodo : function(nome, funcao){
            var that = this;
            that.METODOS.push({NOME: nome, FUNCAO: funcao});
        },
        start: function(){
            var that = this;

            window.addEventListener('message', (event) => {		
                if (event.origin === urlhost) {
                    try {
                        that.METODOS.forEach(function(metodo){
                            if(metodo.NOME == event.data.METODO){
                                metodo.FUNCAO(event.data.PARANS);
                            }
                        });
                    } catch (error) {}
                }
                
            });
        }
    };