(function($) {

	/**
	 * Definir margem do corpo a partir da altura do cabeçalho
	 */
	function margemCorpo() {
		
		$('body > .container-fluid')
			.css('padding-top', $('ul.acoes').height() + 25);
	
	}
	
	/**
	 * Exibir botão que mostra as ações em Mobile.
	 */
	function btnAcoesMobileExibir() {
		
		if ( $(window).width() <= 768 ) {
		
			if ( $('ul.acoes').length > 0 ) {
				$('[data-acoes="toggle"]').addClass('exibir');
			}
			
			// acoes em popup
			$('.popup-acoes-toggle').addClass('exibir');

			// acoes em modal
			$('.btn-toggle-acoes-modal').addClass('exibir');
			
		} else {
		
			$('[data-acoes="toggle"]').removeClass('exibir');
            $('.popup-acoes-toggle').removeClass('exibir');

			// acoes em modal
			$('.btn-toggle-acoes-modal').removeClass('exibir');
		}
		
		if ( $(window).width() <= 1024 ) {
			$('.btn-toggle-acoes-modal-v2').addClass('exibir');
		} else {
			$('.btn-toggle-acoes-modal-v2').removeClass('exibir');
		}

	}
	
	/**
	 * Definir os eventos do botão que mostra as ações em Mobile.
	 */
	function btnAcoesMobile() {

		$(document)
			.on('click','[data-acoes="toggle"]', function() {

				$('ul.acoes').toggleClass('abrir');
			});

		$('.popup')
			.on('click', '.popup-acoes-toggle', function() {

				$('.popup-acoes').toggleClass('abrir');
				return false;
			});

		// acoes em modal
		$(document)
			.on('click', '.btn-toggle-acoes-modal', function() {

				var brns = $('.btn-toggle-acoes-modal');

				var ultimo = {};
				var valido = false;

				$(brns).each(function() {
					if ( $(this).is(':visible') ) {
						valido = true;
						ultimo = this;
					}
				});

				if(valido == true){
					$(ultimo).next('.acoes-modal').toggleClass('abrir');
				}

				return false;
			});


		// acoes em modal
		$(document)
			.on('click', '.btn-toggle-acoes-modal-v2', function() {

				var brns = $('.btn-toggle-acoes-modal-v2');

				var ultimo = {};
				var valido = false;

				$(brns).each(function() {
					if ( $(this).is(':visible') ) {
						valido = true;
						ultimo = this;
					}
				});

				if(valido == true){
					$(ultimo).next('.acoes-modal-v2').toggleClass('abrir');
				}

				return false;
			});
	}

	/**
	 * Definir os eventos do botão que mostra as ações em Mobile.
	 */
	function btnAcoesMobile3() {

		$(document)
			.on('click','[data-acoes3="toggle"]', function() {

				$('ul.acoes3').toggleClass('abrir');
			});

		$('.popup')
			.on('click', '.popup-acoes3-toggle', function() {

				$('.popup-acoes3').toggleClass('abrir');
				return false;
			});

		// acoes em modal
		$(document)
			.on('click', '.btn-toggle-acoes3-modal', function() {

				$('.btn-toggle-acoes3-modal').each(function() {
					
					if ( $(this).is(':visible') ) {

						$(this)
							.next('.acoes3-modal')
							.toggleClass('abrir');

						return false;
					}
				});
			});
	}
	
	$(function() {
		
		//margemCorpo();
		btnAcoesMobileExibir();
		btnAcoesMobile();
		btnAcoesMobile3();
		
		/** Ações ao redimensionar a tela */
		$(window).resize(function() {
			
			btnAcoesMobileExibir();
			
		});
		
	});

})(jQuery);