

/**
 * Script para mensagens de alerta.
 */

/**
 * Alerta para mensagem de erro.
 * @param {string} xhr Mensagem de erro. XHR para erros emitidos pelo Ajax e string para mensagem personalizada.
 */

String.prototype.stripHTML = function() {return this.replace(/<.*?>/g, '');}

function showErro(xhr, b4){
    var msg = '';
    var jsonRet = xhr.responseJSON;

    if ( typeof jsonRet !== 'undefined' && typeof jsonRet.MENSAGEM !== 'undefined' ) {
        msg = xhr.responseJSON.MENSAGEM;
    }else{
        msg = xhr.responseText;
        
        if ( typeof msg !== 'undefined' ) {
            
            if ( msg == '' ) {
                msg = 'Ops! Não obtivemos resposta do nosso servidor. Por favor, tente novamente.<br/>'+$('#MENSAGEM_ERRO').val();
            } else {

                if ( typeof xhr.responseJSON !== 'undefined' ) {
                    msg = xhr.responseJSON.MENSAGEM;
                }
            }
            
        } else {
            
            msg = xhr;
            
        }
    }
    
    return addBalao(msg,'danger', b4);
	
}

/**
 * Alerta para mensagem de sucesso.
 * @param {string} msg
 */
function showSuccess(msg, b4){
    return addBalao(msg,'success', b4);
}

function showCookieAlert(msg, fixo = true, b4 = null){
    return addBalao(msg, 'warning', b4, fixo);
}

$(document).on('click', '.msg-balao-fechar',function(){
    $(this).closest('.balao').find('.balao-fechar').click();
});

$(document).on('click', '.balao-fechar',function(){
    $(this).closest('.balao').slideUp(100, function() {$(this).remove();});
});

/**
 * Alerta para mensagem de sucesso.
 * @param {string} msg
 */
function addBalao(msg, tipo, b4, fixo = false){
    
    var erroRota = false;

    erroRota = (msg+"").indexOf('<div id="main" ng-app="app">') > 0;
    var class_hash = '';

    if(erroRota == false){
        var tipo_alerta = 0;

        if(typeof msg == "object"){
            msg = '';
        }

        var icon = 'default';
        switch(tipo) {
            case 'success':
                icon = 'check';
                tipo_alerta = 1;
                break;
            case 'danger':
                icon = 'times';
                tipo_alerta = 2;
                break;
            case 'warning':
                icon = 'exclamation';
                tipo_alerta = 3;
                break;
            case 'info': 
                icon = 'info';
                tipo_alerta = 4;
                break;
        }
        
        var tipo = 'alert-' + (tipo || 'default');
            class_hash = 'class-' + moment().format('YYYYMMDDHHmmssms') + Math.floor(Math.random() * 500) + 1;
        
        var tab_suporte = '';
        if(tipo_alerta == 2){
            //tab_suporte = '<br><br><div class="balao-suporte" style="cursor: pointer; color: #337ab7;">[Abrir chamado]</div>';
        }

        if(b4 == true){ 
            var balao = 
            '<div class="balao ' + class_hash + ' ' + tipo + '" style="display: none">'+
                '<div class="marca-fixo" title="Este balão está fixo, e só ira sumir se for fechado"></div>'+
                '<div class="balao-fechar"><span title="Fechar" class="fa fa-times"></span></div>'+
                '<div class="balao-fixar" ><span title="Fixar" class="fas fa-thumbtack"></span></div>'+
                '<div class="body">'+
                    msg +
                    tab_suporte +
                '</div>'+
            '</div>';
        }else{
            var balao = 
            '<div class="balao ' + class_hash + ' ' + tipo + '" style="display: none">'+
                '<div class="marca-fixo" title="Este balão está fixo, e só ira sumir se for fechado"></div>'+
                '<div class="balao-fechar"><span title="Fechar" class="fa fa-close"></span></div>'+
                '<div class="balao-fixar" ><span title="Fixar" class="glyphicon glyphicon-pushpin"></span></div>'+
                '<div class="body">'+
                    msg +
                    tab_suporte +
                '</div>'+
            '</div>';
        }

        $('.baloes').prepend(balao);
        setTimeout(function(){
            $('.'+class_hash).slideDown();
        },100);

        if(fixo){
            $('.balao-fixar').click();
            $('.balao-fixar').hide();
        }
    
        setTimeout(function(){
            if(!$('.'+class_hash).hasClass('balao-fixo')){ 
                $('.'+class_hash).slideUp(100, function() {$(this).remove();});
            } 
        }, 7000);  

    }

    return class_hash;

}

function opemEmailSuporte(asunto, erro, corpo, email){

    var dataimg = '';
	console.log('Chamado enviado.');
    addConfirme2('Suporte',''
        +'<div id="div-suport-tab1" class="alert alert-warning" style="display: flex;">Coletando informações, Por favor, aguarde.</div>'
        +'<div id="div-suport-tab2" style="display: none;">'
            +'<input type="text" style="display: none;" name="titulo" class="name-file-send"  value="erro.png" autocomplete="off">'

            +'<div class="alert alert-warning" style="display: flex;">Nos informe um pouco como este erro ocorreu e um telefone para contato que em breve iremos te retornar!</div>'

            +'<div class="itens-inputs">'   
            +'  <div class="form-group">'
            +'      <label>Para:</label>'
            +'      <input type="text" style="width: 100% !important;text-transform: lowercase;" name="titulo" class="email-file-send form-control input-maior" value="'+email+'" autocomplete="off">'
            +'  </div>'
            +'</div>'

            +'<div class="itens-inputs">'
            +'  <div class="form-group">'
            +'      <label>Assunto:</label>'
            +'      <input type="text" style="width: 100% !important;" name="titulo" class="assunto-file-send form-control input-maior normal-case" value="'+asunto+'" autocomplete="off">'
            +'  </div>'
            +'</div>'

            +'<div class="itens-inputs">'
            +'    <div class="form-group">'
            +'        <label>Descrição:</label>'
            +'        <textarea name="" class="form-control corpo-file-send normal-case" rows="5" cols="70" value="'+corpo+'">'+corpo+'</textarea>'
            +'    </div>'
            +'</div>'
        +'</div>',

            [
                {desc:'Enviar',class:'btn-primary btn-confirm-sim',ret:'1',hotkey:'enter',glyphicon:'glyphicon-ok'},
                {desc:'Cancelar',class:'btn-danger btn-confirm-can btn-voltar',ret:'2',hotkey:'esc',glyphicon:'glyphicon-ban-circle'}
            ],
            [
                {ret:1,func:function(){
                    var email  = $('.email-file-send').val();
                    var asunto = $('.assunto-file-send').val();
                    var corpo  = $('.corpo-file-send').val();

                    var user_id  = $('#usuario-id').val();
                    var user_cr  = $('#usuario-descricao').val();
                    var use_ip   = $('#ip-suporte-gcweb').val();

                    var title  = window.document.title;
                    var rotaid = getCookie('ROUTEID');
                    var data   = moment().format('L')+' '+moment().format('LT');

                    var html = '<html><head><meta charset="Content-Type: text/html; charset=UTF-8"><style>p {margin: 2px;}</style></head><body>';
                        html = html + '<p>Repport Error GC</p>';

                        html = html + '<br><p style="font-weight: bold;">############# - Informações - ##############</p>';
                        html = html + '<p></p>';
                        html = html + '<p>Usuário: '+user_id+' - '+user_cr+'</p>';
                        html = html + '<p>IP: '+use_ip+'</p>';
                        html = html + '<p>Página: '+title+'</p>'; 
                        html = html + '<p>Servidor: '+(rotaid == 1 ? 'WebHead 1' : 'WebHead 2')+'</p>';  
                        html = html + '<p>Data/Hora: '+data+'</p>'; 

                        erro = (erro+'').replace('[Abrir chamado]','');

                        html = html + '<br><p style="font-weight: bold;">############# - Mensagem de Erro - ##############</p>' + erro;
                        html = html + '<br><p style="font-weight: bold;">############# - Mensagem do Usuário - ##############</p>' + corpo;
                        //html = html + '<br><br><p style="font-weight: bold;">############# - Imagem do Erro - ##############</p><img src="'+dataimg+'" id="img1" border="0" />';

                        html = html + '</body></html>';
						
                        sendEmailSuporte(email, dataimg ,asunto, html).then(function(){
						console.log('Chamado aberto.');
                        showSuccess('Chamado aberto.');
                        $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                        $('.modal.confirm').remove();
                    });

                }},
                {ret:2,func:function(){
                    $('.modal.confirm').prev('.modal-backdrop.confirm').remove();
                    $('.modal.confirm').remove();
                }}
            ],'N'    
    );

    setTimeout(function(){

        html2canvas( document.querySelector("#fundo-tela"),{
            imageTimeout : 30000,
            allowTaint: true,
            useCORS: true,
            foreignObjectRendering : false,
            logging : true
        }).then(function(canvas){
    
            dataimg = canvas.toDataURL(); 

            $('#div-suport-tab1').hide();
            $('#div-suport-tab2').show();
            $('#div-suport-tab3').show();

        });   
    }, 300);
    
}

/**
 * Alerta para mensagem de sucesso.
 * @param {string} msg
 */
function addNotificacao(msg,titulo,id, flag, agd_id, flag_tipo, de){
    
    if(typeof msg == "object"){
        msg = '';
    }
    
    var icon = 'default';
    switch(tipo) {
        case 'success':
            icon = 'check';
            break;
        case 'danger':
            icon = 'times';
            break;
        case 'warning':
            icon = 'exclamation';
            break;
    }
    
    var tipo = 'alert-' + (tipo || 'default');
    var class_hash = '';

    class_hash = id;

    var agd = '';

    if(agd_id > 0 && (flag_tipo < 6 || flag_tipo == 7) ){
        agd += '<br><br>';
        agd += '<form>';

        if(flag_tipo == 7){
            agd += '  De:'+de+'<br>';
            agd += '  <input type="button" data-id="'+agd_id+'" class="btn-responder-notificacao" data-id="'+agd_id+'" value="Responder">';   
        }else{
            agd += '  Adiar esta notificação:<br>';
            agd += '  <select class="agd-id-'+agd_id+'">';
            agd += '    <option value="5"    >5 Minutos</option>';
            agd += '    <option value="10"   >10 Minutos</option>';
            agd += '    <option value="30"   >30 Minutos</option>';
            agd += '    <option value="60"   >1 hora</option>';
            agd += '    <option value="1440" >1 dia</option>';
            agd += '    <option value="10080">1 semana</option>';
            agd += '    <option value="43200">1 mês</option>';
            agd += '  </select>';
            agd += '  <input type="button" class="btn-agendar-notificacao" data-id="'+agd_id+'" value="Adiar">';
        }

        agd += '</form>';
        
    }
    
    var balao = 
        '<div class="balao ' + class_hash + ' pop-notificacao" data-id="'+class_hash+'" style="display: none">'+
            '<div class="marca-fixo" title="Este balão est fixo, e só ira sumir se for fechado"></div>'+
            '<div data-id="'+class_hash+'" class="balao-fechar '+class_hash+'-fechar"><span title="Fechar" class="fa fa-close"></span></div>'+
            '<div class="body">'+
            '<label>'+titulo+'</label>'+
                msg + agd +
            '</div>'
        '</div>';

    $('.baloes').prepend(balao);

    var msgs = window.localStorage.getItem('MSG');

    if(msgs == null){
        msgs = [];
    }else{
        msgs = JSON.parse(msgs);    
    }

    var validar = 0;
    msgs.forEach(function(iten, index){
        if(iten.ID == id){
            validar = 1;   
        }
    });

    if(validar == 0){
        msgs.push({MSG: msg, TITULO: titulo, ID: class_hash, AGENDAMENTO_ID: agd_id, TIPO : flag_tipo, DE : de});
        window.localStorage.setItem('MSG', JSON.stringify(msgs));

        Notification.requestPermission(function() {

            var notification = new Notification(
                titulo, 
                {
                    icon: '../../assets/images/logo/'+path_dir_empresa+'logo.png',
                    body: msg.stripHTML(),
                    tag : class_hash
                }
            );

            notification.onclick = function() {
                window.focus();
                notification.close();
            };

        });
    }

    setTimeout(function(){
        $('.'+class_hash).slideDown();
        $('.'+class_hash+'-fechar').on('click',function(e){
            var id = $(this).data('id');
            
                var msgs = window.localStorage.getItem('MSG');

                if(msgs == null){
                    msgs = [];
                }else{
                    msgs = JSON.parse(msgs);    
                }

                msgs.forEach(function(iten, index){

                    if(iten.ID == id){
                         msgs.splice(index,1);    
                    }
                });

                window.localStorage.setItem('MSG', JSON.stringify(msgs));

        });
    },100);

    /*
    setTimeout(function(){
        if(!$('.'+class_hash).hasClass('balao-fixo')){ 
            $('.'+class_hash).slideUp(100, function() {$(this).remove();});
        }
    },7000);
    */
}

/**
 * Alerta para mensagem de atenção.
 * @param {string} msg
 */
function showAlert(msg, b4){

    return addBalao(msg,'warning', b4);
    
//	$('.alert-principal')
//		.addClass('alert-warning')
//		.removeClass('alert-danger')
//		.removeClass('alert-success');
//
//	$('.alert-principal .texto')
//		.html(msg)
//		.parent()
//		.slideDown();
//
//	ativarFecharAlert();

}

/**
 * Fechar o alerta após determinado tempo 
 * e se não estiver com o mouse por cima.
 */
function ativarFecharAlert() {

   var time = 0;

   /**
	* Fechar o alerta.
	* @param {object} alert
	*/
   function fechar(alert) {

	   $(alert)
		   .slideUp('medium', function() {

			   $(this)
				   .children('.texto')
				   .empty()
				;
				
				//remover title do botão copiar
				$(this)
					.find('.btn-clipboard')
					.removeAttr('title')
				;
				
		   });
   }

   /**
	* Ativar evento para fechar após determinado tempo
	* ou ao clicar.
	*/
   function eventoFechar() {

	   time = 0;

	   //fechar após determinado tempo
	   time = setTimeout(function() {

		   fechar( $('.alert-principal') );

	   }, 5000);

   }

   /**
	* Iniciar ações para fechar o alerta.
	*/
   function iniciarFechar() {

	   //não permite fechar quando estiver com mouse por cima
	   $('.alert-principal:visible')
		   .hover(function() {

			   clearTimeout(time);

		   }, function() {

			   eventoFechar();

		   });

	   //ativar contagem quando o alert estiver visível
	   if ( $('.alert-principal').is(':visible') ) {

		   eventoFechar();

	   }
	   
	   //fechar com clique
	   $('.alert .fechar')
		   .click(function() {

			   fechar( $(this).closest('.alert') );

		   });

   }

   iniciarFechar();

}

/**
 * Barra de progresso
 * @param {type} valor_atual
 * @param {type} valor_maximo
 */
function progressBar(valor_atual,valor_maximo) {
    
    var div_block = $('.carregando-pagina');
    var barra     = $('.progress-bar');
    var arialmax  = barra.attr('aria-valuemax');
    
    var show = function() {
        barra.attr('aria-valuemax',valor_maximo);
        arialmax = barra.attr('aria-valuemax');
        div_block.show();
    };
    var hide = function() {
        div_block.hide();
        barra.width(0);
        barra.attr('aria-valuemax',0);
    };
    
    if ( valor_maximo > 0 ) {
        show();
    }
    
    if ( (valor_atual == 0 && !valor_maximo) ){ 
        hide();
    }
    
    if ( valor_atual > 0 && arialmax > 0 ) {
        var percentual = ((valor_atual / arialmax)*100).toFixed(2) + '%';
               
        barra
            .width(percentual)
            .on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function() {
                if ( percentual == '100.00%' ) {
                    hide();
                }
            })
        ;
    }
}

function stripHtml(html){
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

(function($) {

    $(document).on('click', '.balao-fixar', function(event) {
        //$(this).parent().find('.marca-fixo').css('display','block');
        $(this).parent().addClass('balao-fixo');
        //$(this).find('span').removeClass('glyphicon-pushpin');
        //$(this).find('span').addClass('glyphicon-info-sign');
        $(this).find('span').attr({'title': 'Este balão está fixo, e só ira sumir se for fechado'});('glyphicon-info-sign');
        $(this).css('opacity','0.4');
    });

    $(document).on('click', '.balao-suporte', function(event) {

        event.stopPropagation();
        
        $(this).parent().parent().addClass('balao-fixo');
        $(this).parent().parent().find('.balao-fixar').find('span').attr({'title': 'Este balão está fixo, e só ira sumir se for fechado'});('glyphicon-info-sign');
        $(this).parent().parent().find('.balao-fixar').css('opacity','0.4');

        var erro   = $(this).parent().html();
        var titulo = stripHtml(erro);
        titulo = (titulo+'').substring(0,40);

        opemEmailSuporte('Repport Error GC - ('+titulo+')', erro, '', $('#email-suporte-gcweb').val() ,'anderson');

    });

	$(function() {
		
		ativarFecharAlert();

        var msgs = window.localStorage.getItem('MSG');

        if(msgs == null){
            msgs = [];
        }else{
            msgs = JSON.parse(msgs);    
        }

        msgs.forEach(function(iten, index){
            addNotificacao(iten.MSG, iten.TITULO, iten.ID, 0, iten.AGENDAMENTO_ID, iten.TIPO, iten.DE); 
        });

        //function storage_event(e){
           // console.log(e);
        //}

        //window.addEventListener('storage', storage_event, false);

        $(window).bind('storage', function (e) {

            var itens = $('.pop-notificacao');
            var msgs = window.localStorage.getItem('MSG');

            if(msgs == null){
                msgs = [];
            }else{
                msgs = JSON.parse(msgs);    
            }

            $(itens).each(function(index,iten){

                var validar = 0;
                var id = $(iten).data('id');

                msgs.forEach(function(a, b){
                    if(a.ID == id){
                        validar = 1;   
                    }
                });

                if(validar == 0){
                    //console.log($('.'+id+'-fechar'));
                    $('.'+id+'-fechar').trigger('click');
                }

            });
		});
	});
	
})(jQuery);

function showCookieAlert(){
    Swal.fire('Teste');
}

function showToast(message, type = 'info', duration = 3000){

    Swal.fire({
        text: message,
        icon: type,
        target: '#swal-target',
        customClass: {
          container: 'position-absolute'
        },
        toast: true,
        timer: duration,
        timerProgressBar: true,
        position: 'bottom-right',
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    })

}

/**
 * ======================================================================================================
 * NÃO DESCOMENTAR (SALVO O CASO DE DESENVOLVIMENTO) ESSE BLOCO ATÉ QUE A LGPD DO GCWEB ESTEJA CONCLUÍDA
 * ======================================================================================================
 */
(function($){
    // Document Ready
})(jQuery);

document.addEventListener('DOMContentLoaded', function() {
    // DOM Content Loaded
    setTimeout(function(){
        /*
        showCookieAlert(
            `<span>
                O GC Web utiliza cookies para oferecer uma melhor experiência de navegação, 
                analisar como você interage com o nosso sistema, otimizar o desempenho e personalizar o conteúdo.
                Ao utilizar o GC Web, você concorda com o uso de cookies. 
                Saiba mais em <a href="">Política de Privacidade</a>
            </span>`
        );
        */
        // SwalCookieAlert();
    },100);
});